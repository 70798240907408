/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Edit.vue?vue&type=template&id=b8b44e3e&scoped=true"
import script from "./Edit.vue?vue&type=script&lang=js"
export * from "./Edit.vue?vue&type=script&lang=js"
import style0 from "./Edit.vue?vue&type=style&index=0&id=b8b44e3e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8b44e3e",
  null
  
)

export default component.exports