import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.map.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_students_create"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v(" " + _vm._s(_vm.studentAddress) + "・保護者情報" + _vm._s(_vm.id ? "更新" : "作成") + " ")]), _vm.isLoading.getData ? _c('loading-message') : _vm._e(), _vm.isLoading.postData ? _c('loading-message', {
    attrs: {
      "loading-message": _vm.loadingMessage
    }
  }) : _vm._e(), _c('form', {
    staticClass: "common_form",
    attrs: {
      "action": "",
      "method": "post"
    }
  }, [_c('section', {
    staticClass: "gray_frame"
  }, [_c('p', {
    staticClass: "gray_frame__heading"
  }, [_vm._v("保護者の設定")]), _c('div', {
    staticClass: "border_frame"
  }, [_c('row-item', {
    staticClass: "start item"
  }, [_c('p', {
    staticClass: "required col_3"
  }, [_vm._v("保護者氏名")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.guardian.name,
      expression: "guardian.name"
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text",
      "placeholder": "山田 花子",
      "readonly": _vm.guardianInfoAttached
    },
    domProps: {
      "value": _vm.guardian.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.guardian, "name", $event.target.value);
      }
    }
  }), _vm.error.name ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.name) + " ")]) : _vm._e()])]), _c('row-item', {
    staticClass: "start item"
  }, [_c('p', {
    staticClass: "required col_3"
  }, [_vm._v("ふりがな")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.guardian.ruby,
      expression: "guardian.ruby"
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text",
      "placeholder": "やまだ はなこ",
      "readonly": _vm.guardianInfoAttached
    },
    domProps: {
      "value": _vm.guardian.ruby
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.guardian, "ruby", $event.target.value);
      }
    }
  }), _vm.error.ruby ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.ruby) + " ")]) : _vm._e()])]), _c('row-item', {
    staticClass: "start item"
  }, [_c('p', {
    staticClass: "required col_3"
  }, [_vm._v("電話番号")]), _c('div', {
    staticClass: "col_7"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.guardian.phone,
      expression: "guardian.phone"
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text",
      "placeholder": "09012345678",
      "readonly": _vm.guardianInfoAttached
    },
    domProps: {
      "value": _vm.guardian.phone
    },
    on: {
      "change": _vm.checkPhoneExists,
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.guardian, "phone", $event.target.value);
      }
    }
  }), _vm.error.phone ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.phone) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "common_form--editor col_2"
  }, [_c('div', {
    staticStyle: {
      "padding": "10px 5px"
    }
  }, [_vm.isLoading.phoneExists ? _c('p', [_vm._v("確認中")]) : _vm._e(), _vm.isNewPhone ? _c('p', [_c('i', {
    staticClass: "fas fa-info-circle"
  }), _vm._v("OK")]) : _vm._e()])])]), _c('row-item', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("メールアドレス")]), _c('div', {
    staticClass: "col_7"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.guardian.email,
      expression: "guardian.email"
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text",
      "placeholder": "sample@example.com",
      "readonly": _vm.guardianInfoAttached
    },
    domProps: {
      "value": _vm.guardian.email
    },
    on: {
      "change": _vm.checkPhoneAndEmailExists,
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.guardian, "email", $event.target.value);
      }
    }
  }), _vm.error.email ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.email) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "common_form--editor col_2"
  }, [_c('div', {
    staticStyle: {
      "padding": "10px 5px"
    }
  }, [_vm.isLoading.isNewPhoneAndEmail ? _c('p', [_vm._v("確認中")]) : _vm._e(), _vm.isNewPhoneAndEmail ? _c('p', [_c('i', {
    staticClass: "fas fa-info-circle"
  }), _vm._v("OK ")]) : _vm._e()])])]), _c('row-item', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("続柄")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.guardian.relationship,
      expression: "guardian.relationship"
    }],
    staticClass: "common_form--input col_4",
    attrs: {
      "type": "text",
      "placeholder": "母",
      "readonly": _vm.guardianInfoAttached
    },
    domProps: {
      "value": _vm.guardian.relationship
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.guardian, "relationship", $event.target.value);
      }
    }
  })])]), _c('row-item', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "common_form--heading col_3"
  }, [_vm._v("言語")]), _c('div', {
    staticClass: "col_9"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.guardian.locale,
      expression: "guardian.locale"
    }],
    staticClass: "common_form--select col_4",
    attrs: {
      "readonly": _vm.guardianInfoAttached
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.guardian, "locale", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "ja-JP"
    }
  }, [_vm._v("日本語")]), _c('option', {
    attrs: {
      "value": "en-US"
    }
  }, [_vm._v("English")]), _c('option', {
    attrs: {
      "value": "es-US"
    }
  }, [_vm._v("Español")]), _c('option', {
    attrs: {
      "value": "zh-CN"
    }
  }, [_vm._v("中国語（簡体字）")])])])]), _c('row-item', {
    staticClass: "start item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("お知らせ方法")]), _c('div', {
    staticClass: "col_9"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.guardian.normal_receive_method,
      expression: "guardian.normal_receive_method"
    }],
    staticClass: "common_form--select",
    attrs: {
      "name": "contact_type",
      "readonly": _vm.guardianInfoAttached
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.guardian, "normal_receive_method", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "email"
    }
  }, [_vm._v("メール")]), _c('option', {
    attrs: {
      "value": "phone"
    }
  }, [_vm._v("電話")])]), _c('div', {
    staticClass: "under_item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isPrimaryGuardian,
      expression: "isPrimaryGuardian"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "cb_isPrimaryGuardian",
      "type": "checkbox",
      "disabled": _vm.questioneeFlagLock
    },
    domProps: {
      "checked": Array.isArray(_vm.isPrimaryGuardian) ? _vm._i(_vm.isPrimaryGuardian, null) > -1 : _vm.isPrimaryGuardian
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.isPrimaryGuardian,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.isPrimaryGuardian = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isPrimaryGuardian = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isPrimaryGuardian = $$c;
        }
      }, function ($event) {
        _vm.guardian.role_id = _vm.isPrimaryGuardian ? 21 : 22;
      }]
    }
  }), _c('label', {
    attrs: {
      "for": "cb_isPrimaryGuardian"
    }
  }, [_vm._v("アンケート回答可能")]), _vm.questioneeFlagLock ? _c('p', {
    staticClass: "note"
  }, [_vm._v(" アンケート回答者を変更する場合は、"), _c('strong', [_vm._v("変更後の回答者の編集画面")]), _vm._v("からこの項目にチェックを入れてください ")]) : _c('p', {
    staticClass: "note"
  }, [_vm._v(" ※アンケート回答者に設定する場合はチェックを入れてください。"), _c('br'), _vm._v(" ※すでにアンケート回答者がいる場合、チェックを入れると、現在の回答者はアンケートに回答できなくなります。 ")])])])]), _vm.capabilities.enableEmergencyCall ? _c('row-item', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("緊急のお知らせ")]), _c('div', {
    staticClass: "col_9"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.guardian.emergency_receive_method,
      expression: "guardian.emergency_receive_method"
    }],
    staticClass: "common_form--select",
    attrs: {
      "name": "contact_type"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.guardian, "emergency_receive_method", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "both"
    }
  }, [_vm._v("メールと電話")]), _c('option', {
    attrs: {
      "value": "email"
    }
  }, [_vm._v("メール")]), _c('option', {
    attrs: {
      "value": "phone"
    }
  }, [_vm._v("電話")])])])]) : _vm._e(), _c('row-item', {
    staticClass: "start item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("メモ")]), _c('div', {
    staticClass: "col_9"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.guardian.memo,
      expression: "guardian.memo"
    }],
    staticClass: "common_form--textarea",
    attrs: {
      "id": "memo",
      "name": "memo",
      "rows": "10"
    },
    domProps: {
      "value": _vm.guardian.memo
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.guardian, "memo", $event.target.value);
      }
    }
  })])])], 1)]), _c('p', {
    staticClass: "attention"
  }, [_vm._v(" 先生により保護者登録・変更が行われると、システムからメールで自動通知をします。 ")]), _c('p', {
    staticClass: "attention"
  }, [_vm._v(" ＊電話のみ登録の場合、自動通知は行いませんので、お手数ですが学校から直接ご連絡ください。 ")]), _c('section', {
    staticClass: "common_form__button_wrap"
  }, [_c('input', {
    staticClass: "cancel_button",
    attrs: {
      "type": "button",
      "value": "キャンセル"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm.id ? _c('input', {
    staticClass: "update_button",
    attrs: {
      "type": "button",
      "value": "更新する"
    },
    on: {
      "click": _vm.saveData
    }
  }) : _c('input', {
    staticClass: "register_button",
    attrs: {
      "type": "button",
      "value": "登録する"
    },
    on: {
      "click": _vm.saveData
    }
  })])]), _vm.showModal ? _c('message-modal', {
    on: {
      "close": _vm.modalClosed
    }
  }) : _vm._e(), _vm.showModalPhoneExists ? _c('section', {
    staticClass: "common_modal"
  }, [_c('div', {
    staticClass: "common_modal__inner"
  }, [_c('div', {
    staticClass: "common_modal__white_frame",
    attrs: {
      "id": "targetFrame"
    }
  }, [_c('div', {
    staticClass: "common_modal__inner_frame",
    attrs: {
      "id": "modal_sentSettled"
    }
  }, [_c('h3', {
    staticClass: "common_modal__title"
  }, [_vm._v("確認")]), _c('p', {
    staticClass: "common_modal__text"
  }, [_vm._v(" 入力された電話番号は "), _c('strong', [_vm._v(_vm._s(_vm.foundGuardian.name))]), _vm._v(" さんとして登録されています。"), _c('br'), _vm._v(" " + _vm._s(_vm.foundGuardian.name) + "さんをこの生徒の保護者として登録してもよろしいですか？ ")]), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('input', {
    staticClass: "cancel_button",
    attrs: {
      "type": "button",
      "value": "いいえ"
    },
    on: {
      "click": _vm.cancelAttachGuardian
    }
  }), _c('input', {
    staticClass: "update_button",
    attrs: {
      "type": "button",
      "value": "はい"
    },
    on: {
      "click": _vm.attachGuardian
    }
  })])])])])]) : _vm._e(), _vm.showModalPhoneAndEmailExists ? _c('section', {
    staticClass: "common_modal"
  }, [_c('div', {
    staticClass: "common_modal__inner"
  }, [_c('div', {
    staticClass: "common_modal__white_frame",
    attrs: {
      "id": "targetFrame"
    }
  }, [_c('div', {
    staticClass: "common_modal__inner_frame",
    attrs: {
      "id": "modal_sentSettled"
    }
  }, [_c('h3', {
    staticClass: "common_modal__title"
  }, [_vm._v("確認")]), _c('p', {
    staticClass: "common_modal__text"
  }, [_vm._v(" 入力された電話番号及びメールアドレスは "), _c('strong', [_vm._v(_vm._s(_vm.foundGuardian.name))]), _vm._v(" さんとして登録されています。"), _c('br'), _vm._v(" " + _vm._s(_vm.foundGuardian.name) + "さんをこの生徒の保護者として登録してもよろしいですか？ ")]), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('input', {
    staticClass: "cancel_button",
    attrs: {
      "type": "button",
      "value": "いいえ"
    },
    on: {
      "click": _vm.cancelAttachGuardian
    }
  }), _c('input', {
    staticClass: "update_button",
    attrs: {
      "type": "button",
      "value": "はい"
    },
    on: {
      "click": _vm.attachGuardian
    }
  })])])])])]) : _vm._e(), _vm.showModalDuplicativeGuardianExists ? _c('section', {
    staticClass: "common_modal"
  }, [_c('div', {
    staticClass: "common_modal__inner"
  }, [_c('div', {
    staticClass: "common_modal__white_frame",
    attrs: {
      "id": "targetFrame"
    }
  }, [_c('div', {
    staticClass: "common_modal__inner_frame",
    attrs: {
      "id": "modal_sentSettled"
    }
  }, [_c('h3', {
    staticClass: "common_modal__title"
  }, [_vm._v("エラー")]), _vm._m(0), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('input', {
    staticClass: "cancel_button",
    attrs: {
      "type": "button",
      "value": "閉じる"
    },
    on: {
      "click": function click($event) {
        _vm.showModalDuplicativeGuardianExists = false;
      }
    }
  })])])])])]) : _vm._e()], 1);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c('p', {
    staticClass: "common_modal__text"
  }, [_vm._v(" 先生のお手元で登録ができない保護者様のようです。"), _c('br'), _vm._v(" COCOOサポート事務局で登録を代行させていただくため、"), _c('br'), _vm._v(" お手数ですが次のフォームへ登録内容をご記入ください。"), _c('br'), _c('a', {
    attrs: {
      "href": "https://tayori.com/f/cocoo-parent-entry-proxy/",
      "target": "_blank"
    }
  }, [_vm._v("「登録依頼フォームを開く」")])]);
}];
export { render, staticRenderFns };