import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import moment from "moment";
export default {
  name: "CalenderHeading",
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object,
      default: function _default() {}
    }
  },
  computed: {
    localYearMonth: function localYearMonth() {
      return moment(this.filter.yearMonth).format("YYYY年MM月");
    }
  },
  methods: {
    handleMonthPager: function handleMonthPager(mode) {
      if (!this.isLoading) {
        var date = moment(this.filter.yearMonth);

        if (mode === "add") {
          date.add(1, "months");
        }

        if (mode === "sub") {
          date.subtract(1, "months");
        }

        this.$router.push({
          name: this.$route.name,
          query: this.$route.query.temperature ? {
            classroom_id: this.filter.classroomId,
            group_id: this.filter.groupId,
            year_month: date.format("YYYY-MM"),
            temperature: "1"
          } : {
            classroom_id: this.filter.classroomId,
            group_id: this.filter.groupId,
            year_month: date.format("YYYY-MM")
          }
        });
        this.$emit("init");
      }
    }
  }
};