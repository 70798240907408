import _objectSpread from "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.flat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.unscopables.flat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { mapState } from "vuex";
import { iconColor } from "@/consts/iconColor";
export default {
  name: "GradeTable",
  props: {
    todayAttendance: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      attendanceList: {}
    };
  },
  computed: _objectSpread({}, mapState(["grades"])),
  watch: {
    todayAttendance: {
      immediate: true,
      handler: function handler() {
        var _this = this;

        this.attendanceList = {};
        this.grades.forEach(function (grade) {
          _this.attendanceList[grade.name] = {};
          grade.classroom.forEach(function (classroom) {
            var attendanceAlignmentList = [[], [], [], [], [], [], []];

            _this.todayAttendance.filter(function (attendance) {
              return attendance.student.grade_id === grade.id && attendance.student.class_id === classroom.id;
            }).forEach(function (attendance) {
              attendance.isAbsentList3 = attendance.absents.find(function (v) {
                return v === 3;
              });
              attendance.isAbsentList20 = attendance.absents.find(function (v) {
                return v === 20;
              });
              attendance.isAbsentList30 = attendance.absents.find(function (v) {
                return v === 30;
              }); // 暫定的に、欠席→遅刻→早退→遅刻早退→登校→下校->未登校の順に表示する

              var type = attendance.type.split("-")[0];

              switch (type) {
                case "欠席":
                  attendanceAlignmentList[0].push(attendance);
                  break;

                case "遅刻":
                  attendanceAlignmentList[1].push(attendance);
                  break;

                case "早退":
                  attendanceAlignmentList[2].push(attendance);
                  break;

                case "遅刻早退":
                  attendanceAlignmentList[3].push(attendance);
                  break;

                case "登校":
                  attendanceAlignmentList[4].push(attendance);
                  break;

                case "下校":
                  attendanceAlignmentList[5].push(attendance);
                  break;

                case "未登校":
                  attendanceAlignmentList[6].push(attendance);
                  break;
              }
            });

            _this.attendanceList[grade.name][classroom.name] = attendanceAlignmentList.flat();
          });
        });
      }
    }
  },
  methods: {
    iconColor: iconColor
  }
};