import _objectSpread from "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { login } from "@/services/auth";
import { mapState } from "vuex";
import emailValidator from "email-validator";
import axios from "axios";
import * as Sentry from "@sentry/vue";
export default {
  name: "Mfa",
  data: function data() {
    return {
      MFACodeIsSent: false,
      email: "",
      MFACode: "",
      error: {
        email: null
      }
    };
  },
  head: {
    title: function title() {
      return {
        inner: "ログイン"
      };
    },
    meta: []
  },
  computed: _objectSpread({}, mapState(["news"])),
  created: function created() {
    this.isForceLogout = !!localStorage.getItem("FORCE_LOGOUT");
  },
  methods: {
    sendMFACode: function sendMFACode() {
      var _this = this;

      var valid = true;

      if (!this.email) {
        this.error.email = "メールアドレスを入力してください。";
        valid = false;
      } else if (!emailValidator.validate(this.email)) {
        this.error.email = "メールアドレスの形式が正しくありません。";
        valid = false;
      }

      if (!valid) {
        return;
      }

      axios.post(process.env.VUE_APP_API_ENDPOINT_BASE + "/sendMFACode", {
        email: this.email
      }).then(function (response) {
        console.log(response.data);

        if (response.data) {
          _this.MFACodeIsSent = true;
        }
      }).catch(function (error) {
        console.log(error);
        _this.error.email = "何らかの理由で認証コードを送ることに失敗しました。";
        _this.MFACodeIsSent = false;
        Sentry.captureException(error);
      });
    },
    handleLogin: function handleLogin() {
      login(this.MFACode);
    }
  }
};