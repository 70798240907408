import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.reduce.js";
import moment from "moment";
import { computed, toRefs } from "vue";
export default {
  __name: 'StatusRate',
  props: {
    to: String,
    statuses: Array,
    message: Object,
    studentAddress: String
  },
  setup: function setup(__props) {
    var props = __props;

    var _toRefs = toRefs(props),
        message = _toRefs.message,
        statuses = _toRefs.statuses;

    var studentMessageCountObj = computed(function () {
      return statuses.value.reduce(function (sum, current) {
        return {
          total: sum.total + current.guardian.length,
          delivered: sum.delivered + current.guardian.filter(function (w) {
            return w.status === "opened" || w.status === "reached";
          }).length
        };
      }, {
        total: 0,
        delivered: 0
      });
    });
    var studentDeliverRate = computed(function () {
      return studentMessageCountObj.value.total ? measureRate(studentMessageCountObj.value.delivered, studentMessageCountObj.value.total) : 0;
    });
    var studentOpenRate = computed(function () {
      return message.value.student_sending_count ? measureRate(message.value.student_opened_count, message.value.student_sending_count) : 0;
    });
    var participantDelivered = computed(function () {
      return statuses.value.filter(function (w) {
        return w.final_status === "opened" || w.final_status === "reached";
      }).length;
    });
    var participantDeliverRate = computed(function () {
      return message.value.participant_sending_count ? measureRate(participantDelivered.value, message.value.participant_sending_count) : 0;
    });
    var participantOpenRate = computed(function () {
      return message.value.participant_sending_count ? measureRate(message.value.participant_opened_count, message.value.participant_sending_count) : 0;
    });
    var isPastYearMessage = computed(function () {
      return moment(message.value.message_scheduled_for).add(-3, "month").year() < moment().add(-3, "month").year();
    });

    var measureRate = function measureRate(molecule, denominator) {
      return Math.round(molecule / denominator * 1000) / 10;
    };

    return {
      __sfc: true,
      props: props,
      message: message,
      statuses: statuses,
      studentMessageCountObj: studentMessageCountObj,
      studentDeliverRate: studentDeliverRate,
      studentOpenRate: studentOpenRate,
      participantDelivered: participantDelivered,
      participantDeliverRate: participantDeliverRate,
      participantOpenRate: participantOpenRate,
      isPastYearMessage: isPastYearMessage,
      measureRate: measureRate
    };
  }
};