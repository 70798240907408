import _objectSpread from "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.some.js";
import { getData } from "@/services/axios";
import { mapState } from "vuex";
import RowItem from "../RowItem";
import ConfirmParticipantQr from "./ConfirmParticipantQr.vue";
export default {
  name: "ParticipantsIndex",
  components: {
    RowItem: RowItem,
    ConfirmParticipantQr: ConfirmParticipantQr
  },
  data: function data() {
    return {
      isLoading: {
        participants: true
      },
      filterKeyword: "",
      participants: [],
      // API からとってきたオリジナルデータ
      visibleParticipants: [],
      // 加工表示用
      showConfirmModal: false
    };
  },
  head: {
    title: function title() {
      return {
        inner: "関係者一覧"
      };
    },
    meta: []
  },
  computed: _objectSpread({}, mapState(["grades", "me"])),
  created: function created() {
    this.setData();
  },
  methods: {
    setData: function setData() {
      var _this = this;

      getData("participants").then(function (data) {
        _this.participants = _this.visibleParticipants = data.items;
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this.isLoading.participants = false;
      });
    },
    filterData: function filterData(e) {
      e.preventDefault();
      var participants = this.participants;
      var keyword = this.filterKeyword;

      if (keyword) {
        participants = participants.filter(function (v) {
          return Object.keys(v).some(function (k) {
            return String(v[k]).indexOf(keyword) > -1;
          });
        });
      }

      this.visibleParticipants = participants;
    },
    confirmGetQrCode: function confirmGetQrCode() {
      this.showConfirmModal = true;
    },
    cancelConfirm: function cancelConfirm() {
      this.showConfirmModal = false;
    }
  }
};