import "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/core-js/modules/es.array.iterator.js";
import "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/core-js/modules/es.promise.js";
import "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/core-js/modules/es.object.assign.js";
import "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import Vue from "vue";
import router from "./router";
import store from "./services/store";
import App from "./App.vue";
import VueHead from "vue-head";
import VueContentPlaceholders from "vue-content-placeholders";
import { isLoggedIn, logout } from "@/services/auth";
import IdleVue from "idle-vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import { createPinia, PiniaVuePlugin } from "pinia";
import contenteditable from "vue-contenteditable";
import vClickOutside from "v-click-outside";
import * as Sentry from "@sentry/vue";
Sentry.init({
  Vue: Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.APP_ENV,
  integrations: [Sentry.vueIntegration({
    tracingOptions: {
      trackComponents: true,
      hooks: ["mount", "update", "unmount"]
    }
  }), Sentry.browserTracingIntegration({
    router: router
  }), Sentry.replayIntegration()],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});
Vue.config.productionTip = false;
Vue.use(VueHead, {
  separator: " | "
});
Vue.use(VueContentPlaceholders);
var eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: process.env.VUE_APP_TIME_TO_AUTO_LOGOUT ? process.env.VUE_APP_TIME_TO_AUTO_LOGOUT : 1000 * 60 * 60
});
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
Vue.filter("shorten", function (value, length) {
  if (!value) return "";
  if (!length) length = 10;

  if (value.length <= length) {
    return value;
  }

  return value.slice(0, length) + "...";
});
Vue.filter("attendanceShort", function (value) {
  if (!value) return "";
  value = value.replace("欠席-", " ");
  value = value.replace("感染性胃腸炎", "感染性");
  value = value.replace("インフルエンザ", "インフル");
  return value.replace(/\s+/, " ");
});
Vue.component(VueQrcode.name, VueQrcode);
Vue.use(PiniaVuePlugin);
var pinia = createPinia();
Vue.use(contenteditable);
Vue.use(vClickOutside);
new Vue({
  router: router,
  created: function created() {
    if (isLoggedIn() && (!this.$store.school || !this.$store.grades || !this.$store.classrooms || !this.$store.groups)) {
      this.$router.push({
        name: "appInit"
      });
    }
  },
  render: function render(h) {
    return h(App);
  },
  onIdle: function onIdle() {
    if (isLoggedIn()) {
      localStorage.setItem("FORCE_LOGOUT", "yes");
      logout();
    }
  },
  template: "<App/>",
  store: store,
  pinia: pinia
}).$mount("#app");