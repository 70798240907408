import _objectSpread from "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/web.dom-collections.for-each.js";
import moment from "moment";
import { getData } from "@/services/axios";
import { mapState } from "vuex";
import RowItem from "../RowItem";
export default {
  name: "AnswerSummary",
  components: {
    RowItem: RowItem
  },
  data: function data() {
    return {
      question: {},
      enquete_subject: "",
      question_subject: "",
      options: [],
      answers: [],
      filteredAnswers: [],
      filterOptions: {
        classroom: false,
        answer: false
      },
      isLoading: true
    };
  },
  head: {
    title: function title() {
      return {
        inner: "アンケート詳細 - 個別回答結果"
      };
    },
    meta: []
  },
  computed: _objectSpread(_objectSpread({}, mapState(["studentAddress"])), {}, {
    toStudents: function toStudents() {
      return this.$route.query.to === "students";
    },
    classroomsOption: function classroomsOption() {
      var newList = [];

      if (this.toStudents) {
        // 重複を消す
        var c = this.answers.filter(function (v, i, a) {
          return a.findIndex(function (w) {
            return v.student.classroom.id === w.student.classroom.id;
          }) === i;
        });
        c.forEach(function (v) {
          newList.push({
            id: v.student.classroom.id,
            name: v.student.classroom.name
          });
        });
      }

      return newList;
    }
  }),
  created: function created() {
    this.setData();
  },
  methods: {
    setData: function setData() {
      var _this = this;

      getData("reader/get_enquete_answers_by_question?question_id=" + this.$route.query.question_id).then(function (data) {
        _this.question = data.items.question;
        var enqueteContents = _this.question.enquete.enquete_contents_i18n[0];

        if (enqueteContents) {
          _this.enquete_subject = enqueteContents.subject;
        }

        var questionContents = _this.question.question_contents_i18n[0];

        if (questionContents) {
          _this.question_subject = questionContents.subject;
          _this.options = questionContents.options;
        }

        _this.answers = _this.filteredAnswers = _this.$route.query.to === "students" ? data.items.answers.students : data.items.answers.participants;
      }).catch(function (error) {
        console.log(error); // axiosのresponseが存在するエラーの時は一覧に戻る
        // if (error.response) {
        //   this.$router.push({ name: "enquetes" });
        // }
      }).then(function () {
        _this.isLoading = false;
      });
    },
    getAnswerText: function getAnswerText(answer) {
      return this.options[answer.body];
    },
    getJPTime: function getJPTime(datetime) {
      return moment(datetime).isValid() ? moment(datetime).format("llll") : "";
    },
    listFilter: function listFilter() {
      var _this2 = this;

      var newList = this.answers;

      if (this.toStudents) {
        if (this.filterOptions.classroom !== false) {
          newList = newList.filter(function (v) {
            return v.student.classroom.id === _this2.filterOptions.classroom;
          });
        }
      }

      if (this.filterOptions.answer !== false) {
        newList = newList.filter(function (v) {
          return parseInt(v.body) === _this2.filterOptions.answer;
        });
      }

      this.filteredAnswers = newList;
    },
    resetFilter: function resetFilter() {
      this.filterOptions = {
        classroom: false,
        answer: false
      };
    }
  }
};