import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.map.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('main', {
    staticClass: "main page_group_create"
  }, [_setup.isEditMode ? _c('h1', {
    staticClass: "title--page"
  }, [_vm._v("グループ管理 - グループ編集")]) : _c('h1', {
    staticClass: "title--page"
  }, [_vm._v("グループ管理 - グループ登録")]), _setup.isEditMode && _vm.isLoading.editData ? _c(_setup.LoadingMessage, {
    attrs: {
      "loading-message": "グループ情報取得中"
    }
  }) : _c('form', {
    staticClass: "common_form",
    attrs: {
      "action": "",
      "method": "post"
    }
  }, [_c('div', {
    staticClass: "gray_frame"
  }, [_c(_setup.RowItem, [_c('p', {
    staticClass: "col_1"
  }, [_vm._v("氏名検索")]), _c('div', {
    staticClass: "col_2"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.keywordFilter,
      expression: "keywordFilter"
    }],
    staticClass: "common_form--input col_11",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.keywordFilter
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.keywordFilter = $event.target.value;
      }
    }
  })]), _vm.editData.type === 'student' ? [_c('p', {
    staticClass: "col_1"
  }, [_vm._v("クラス")]), _c('div', {
    staticClass: "col_2"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.classroomFilter,
      expression: "classroomFilter"
    }],
    staticClass: "common_form--select col_10",
    attrs: {
      "id": "selectClass",
      "name": "selectClass"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.classroomFilter = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("全校" + _vm._s(_setup.studentAddress))]), _vm._l(_setup.classrooms, function (classroom, i) {
    return _c('option', {
      key: i,
      domProps: {
        "value": classroom.id
      }
    }, [_vm._v(" " + _vm._s(classroom.name) + " ")]);
  })], 2)])] : _vm._e(), _c('div', {
    staticClass: "under_checkbox col_3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.showCurrentGroup,
      expression: "showCurrentGroup"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "toggle_current_group",
      "type": "checkbox"
    },
    domProps: {
      "value": true,
      "checked": Array.isArray(_vm.showCurrentGroup) ? _vm._i(_vm.showCurrentGroup, true) > -1 : _vm.showCurrentGroup
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.showCurrentGroup,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = true,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.showCurrentGroup = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.showCurrentGroup = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.showCurrentGroup = $$c;
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "toggle_current_group"
    }
  }, [_vm._v("現在のグループを表示")])]), _c('div', {
    staticClass: "under_checkbox col_3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.showOnlySelected,
      expression: "showOnlySelected"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "show_selected",
      "type": "checkbox"
    },
    domProps: {
      "value": true,
      "checked": Array.isArray(_vm.showOnlySelected) ? _vm._i(_vm.showOnlySelected, true) > -1 : _vm.showOnlySelected
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.showOnlySelected,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = true,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.showOnlySelected = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.showOnlySelected = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.showOnlySelected = $$c;
        }
      }
    }
  }), _vm.editData.type === 'student' ? _c('label', {
    attrs: {
      "for": "show_selected"
    }
  }, [_vm._v(" 選択済みのみを表示 （" + _vm._s(_vm.editData.students.length) + "） ")]) : _vm.editData.type === 'participant' ? _c('label', {
    attrs: {
      "for": "show_selected"
    }
  }, [_vm._v(" 選択済みのみを表示 （" + _vm._s(_vm.editData.participants.length) + "） ")]) : _vm._e()])], 2)], 1), !_setup.isEditMode ? _c(_setup.RowItem, {
    staticClass: "form_wrap"
  }, [_c('label', {
    staticClass: "required col_3",
    attrs: {
      "for": "form_el_input_name"
    }
  }, [_vm._v("グループ種別")]), _c('div', {
    staticClass: "col_9"
  }, [_c(_setup.RowItem, [_c('div', {
    staticClass: "radio_wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.editData.type,
      expression: "editData.type"
    }],
    staticClass: "common_form--radio",
    attrs: {
      "id": "typeStudent",
      "type": "radio",
      "name": "type",
      "value": "student"
    },
    domProps: {
      "checked": _vm._q(_vm.editData.type, "student")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.editData, "type", "student");
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "typeStudent"
    }
  }, [_vm._v(_vm._s(_setup.studentAddress))])]), _c('div', {
    staticClass: "radio_wrap"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.editData.type,
      expression: "editData.type"
    }],
    staticClass: "common_form--radio",
    attrs: {
      "id": "typeParticipant",
      "type": "radio",
      "name": "type",
      "value": "participant"
    },
    domProps: {
      "checked": _vm._q(_vm.editData.type, "participant")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.editData, "type", "participant");
      }
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "typeParticipant"
    }
  }, [_vm._v("関係者")])])])], 1)]) : _vm._e(), _c(_setup.RowItem, {
    staticClass: "form_wrap"
  }, [_c('label', {
    staticClass: "required col_3",
    attrs: {
      "for": "form_el_input_name"
    }
  }, [_vm._v("グループ名")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.editData.name,
      expression: "editData.name"
    }],
    staticClass: "common_form--input col_6",
    attrs: {
      "id": "form_el_input_name",
      "type": "text"
    },
    domProps: {
      "value": _vm.editData.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.editData, "name", $event.target.value);
      }
    }
  }), _vm.error.name ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.name) + " ")]) : _vm._e()])]), _vm.error.members ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.members) + " ")]) : _vm._e(), _vm.editData.type === 'student' ? _c('div', [_vm.isLoading.students ? _c(_setup.RowItem, {
    staticClass: "form_wrap"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v(_vm._s(_setup.studentAddress) + "取得中")]), _c('div', {
    staticClass: "col_9"
  }, _vm._l(12, function (n) {
    return _c('div', {
      key: n,
      staticClass: "common_form--radio_wrap"
    }, [_c('content-placeholders', {
      staticClass: "list_placeholder"
    }, [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1)], 1);
  }), 0)]) : _vm._e(), _c('h2', {
    staticClass: "required"
  }, [_vm._v(" " + _vm._s(_setup.studentAddress) + " ")]), _c('div', {
    staticClass: "targets_list"
  }, [_vm.filteredStudentsList.length > 0 ? _c('div', [_c('input', {
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "allCheckS",
      "type": "checkbox",
      "name": "allCheck"
    },
    domProps: {
      "checked": _vm.isAllSelected
    },
    on: {
      "change": function change($event) {
        return _vm.changeAll($event);
      }
    }
  }), _c('label', {
    staticClass: "common_form--label col_3",
    attrs: {
      "for": "allCheckS"
    }
  }, [_vm._v(" すべて選択 ")]), _c(_setup.RowItem, {
    staticClass: "start wrap"
  }, _vm._l(_vm.filteredStudentsList, function (student) {
    return _c('div', {
      key: student.id,
      staticClass: "col_3 checkbox"
    }, [_c('p', [_vm._v(_vm._s(_vm._f("shorten")(_vm.getCurrentClassName(student.class_id))))]), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.editData.students,
        expression: "editData.students"
      }],
      staticClass: "common_form--checkbox",
      attrs: {
        "id": 'student_' + student.id,
        "type": "checkbox"
      },
      domProps: {
        "value": student.id,
        "checked": Array.isArray(_vm.editData.students) ? _vm._i(_vm.editData.students, student.id) > -1 : _vm.editData.students
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.editData.students,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = student.id,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.editData, "students", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.editData, "students", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.editData, "students", $$c);
          }
        }
      }
    }), _c('label', {
      staticClass: "common_form--label",
      attrs: {
        "for": 'student_' + student.id
      }
    }, [_vm._v(" " + _vm._s(_vm._f("shorten")(student.name)) + " ")]), _vm.showCurrentGroup ? _c('div', {
      staticClass: "current_group"
    }, [_c('ul', {
      staticClass: "current_group__list"
    }, [student.group.length === 0 ? _c('li', [_vm._v("所属なし")]) : _vm._e(), _vm._l(student.group, function (group) {
      return _c('li', {
        key: group.id,
        class: {
          is_editing_group: group.id === _vm.$route.params.id
        }
      }, [_vm._v(" " + _vm._s(_vm._f("shorten")(group.name)) + " ")]);
    })], 2)]) : _vm._e()]);
  }), 0)], 1) : _vm._e()])], 1) : _c('div', [_vm.isLoading.participants ? _c('div', {
    staticClass: "common_form--wrap"
  }, [_c('p', {
    staticClass: "common_form--heading col_3"
  }, [_vm._v("関係者取得中")]), _c('div', {
    staticClass: "common_form--editor col_9"
  }, _vm._l(12, function (n) {
    return _c('div', {
      key: n,
      staticClass: "common_form--radio_wrap"
    }, [_c('content-placeholders', {
      staticClass: "list_placeholder"
    }, [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1)], 1);
  }), 0)]) : _vm._e(), _c('h2', {
    staticClass: "required"
  }, [_vm._v("関係者")]), _c('div', {
    staticClass: "targets_list"
  }, [_vm.filteredParticipantsList.length > 0 ? _c('div', {
    staticClass: "common_form--editor"
  }, [_c('input', {
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "allCheckP",
      "type": "checkbox",
      "name": "allCheck"
    },
    domProps: {
      "checked": _vm.isAllSelected
    },
    on: {
      "change": function change($event) {
        return _vm.changeAll($event);
      }
    }
  }), _c('label', {
    staticClass: "common_form--label col_3",
    attrs: {
      "for": "allCheckP"
    }
  }, [_vm._v(" すべて選択 ")]), _c(_setup.RowItem, {
    staticClass: "wrap start"
  }, _vm._l(_vm.filteredParticipantsList, function (participant) {
    return _c('div', {
      key: participant.id,
      staticClass: "col_3 checkbox_wrapper"
    }, [_c('p', [_vm._v(_vm._s(_vm._f("shorten")(participant.belonging)))]), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.editData.participants,
        expression: "editData.participants"
      }],
      staticClass: "common_form--checkbox",
      attrs: {
        "id": 'participant_' + participant.id,
        "type": "checkbox"
      },
      domProps: {
        "value": participant.id,
        "checked": Array.isArray(_vm.editData.participants) ? _vm._i(_vm.editData.participants, participant.id) > -1 : _vm.editData.participants
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.editData.participants,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = participant.id,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.editData, "participants", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.editData, "participants", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.editData, "participants", $$c);
          }
        }
      }
    }), _c('label', {
      staticClass: "common_form--label",
      attrs: {
        "for": 'participant_' + participant.id
      }
    }, [_vm._v(_vm._s(_vm._f("shorten")(participant.name)))]), _vm.showCurrentGroup ? _c('div', {
      staticClass: "current_group"
    }, [_c('ul', {
      staticClass: "current_group__list"
    }, [participant.group.length === 0 ? _c('li', [_vm._v("所属なし")]) : _vm._e(), _vm._l(participant.group, function (group) {
      return _c('li', {
        key: group.id,
        class: {
          is_editing_group: group.id === _vm.$route.params.id
        }
      }, [_vm._v(" " + _vm._s(group.name) + " ")]);
    })], 2)]) : _vm._e()]);
  }), 0)], 1) : _vm._e()])]), _vm.warning ? _c('p', {
    staticClass: "warning"
  }, [_vm._v(" " + _vm._s(_vm.warning) + " ")]) : _vm._e(), _c('section', {
    staticClass: "button_wrapper tac"
  }, [_c('router-link', {
    staticClass: "cancel_button",
    attrs: {
      "to": {
        name: 'groups'
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('input', {
    staticClass: "register_button",
    attrs: {
      "type": "button",
      "value": "登録する",
      "disabled": _vm.dataUpdating
    },
    on: {
      "click": _vm.saveData
    }
  }), _setup.isEditMode ? _c('input', {
    staticClass: "delete_button",
    attrs: {
      "type": "button",
      "value": "削除する",
      "disabled": _vm.dataUpdating
    },
    on: {
      "click": _vm.confirmDelete
    }
  }) : _vm._e()], 1)], 1), _vm.showCompleteModal ? _c(_setup.MessageModal, {
    attrs: {
      "settings": _vm.localModalSettings
    },
    on: {
      "close": _vm.completeRefresh
    }
  }) : _vm._e(), _vm.showConfirmModal ? _c(_setup.ConfirmModal, {
    attrs: {
      "confirm-message": _vm.confirmMessage,
      "confirm-message-title": _vm.confirmMessageTitle
    },
    on: {
      "clickOK": _vm.handleDeleteData,
      "clickCancel": _vm.cancelConfirm
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };