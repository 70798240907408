import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.map.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_class_index"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("年次処理")]), _vm.done ? _c('div', {
    staticClass: "gray_frame"
  }, [_vm._v("今年度の年次処理は実施済みです。")]) : [_vm.isConfigured ? _c('div', {
    staticClass: "success"
  }, [_c('p', {
    staticClass: "heading"
  }, [_vm._v("【設定完了】 " + _vm._s(_vm.fiscalDate) + " 0:00 実行予定")]), _c('p', [_vm._v("※進級ルールは" + _vm._s(_vm.nextYear) + "年3月31日まで変更可能")])]) : _c('div', {
    staticClass: "notice"
  }, [_vm._v(" 年次処理ルールが未設定です"), _c('br'), _vm._v(" 進級ルールの設定を確認してください。 ")]), _c('div', {
    staticClass: "gray_frame"
  }, [_vm._v(" " + _vm._s(_vm.fiscalDate) + "以降の学年・組の繰り上がり表記を指定してください。例）1年 → 2年"), _c('br'), _vm._v(" 6年生など、卒業してしまう学年の場合には「卒業する学年」にチェックを入れてください。"), _c('br'), _vm._v(" この設定は、" + _vm._s(_vm.fiscalDate) + "に自動的にシステムに反映されます。 ")]), _c('div', {
    staticClass: "elevating_date"
  }, [_c('row-item', [_c('p', {
    staticClass: "required col_2"
  }, [_vm._v("年次処理実行日")]), _c('div', {
    staticClass: "common_form__editor col_9"
  }, [_c('VueCtkDateTimePicker', {
    attrs: {
      "label": "日付",
      "no-button-now": "",
      "only-date": "",
      "locale": "ja",
      "formatted": "LL",
      "output-format": "YYYY-MM-DD"
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "classrooms_table"
  }, [_c('row-item', {
    staticClass: "classrooms_table__heading"
  }, [_c('p', {
    staticClass: "col_2"
  }, [_vm._v("学年")]), _c('p', {
    staticClass: "col_3"
  }, [_vm._v("組")]), _c('p', {
    staticClass: "col_2"
  }, [_vm._v(_vm._s(_vm.studentAddress) + "数")]), _c('p', {
    staticClass: "col_5"
  }, [_vm._v("次年度")])]), _vm.isLoading.classroomData ? _c('div', {
    staticClass: "classrooms_table__content"
  }, _vm._l(5, function (grade, i) {
    return _c('row-item', {
      key: i
    }, [_c('div', {
      staticClass: "col_12"
    }, [_c('content-placeholders', [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1), _c('div', {
      staticClass: "loadingMask"
    })], 1)]);
  }), 1) : _vm._l(_vm.grades, function (grade, i) {
    return _c('div', {
      key: i,
      staticClass: "classrooms_table__content"
    }, [_c('row-item', {
      staticClass: "content_heading"
    }, [_c('p', {
      staticClass: "col_2"
    }, [_vm._v(" " + _vm._s(grade.name) + " ")]), _c('p', {
      staticClass: "col_5"
    }), _c('div', {
      staticClass: "col_5"
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.newGrades[grade.id].name,
        expression: "newGrades[grade.id].name"
      }],
      staticClass: "common_form--input",
      on: {
        "change": [function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.newGrades[grade.id], "name", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }, function ($event) {
          return _vm.handleChangeGrade($event, grade);
        }]
      }
    }, [_c('option', {
      domProps: {
        "value": _vm.graduatingLabel
      }
    }, [_vm._v(" " + _vm._s(_vm.graduatingLabel) + " ")]), _vm._l(_vm.grades, function (g, i2) {
      return _c('option', {
        key: i2,
        domProps: {
          "value": g.name
        }
      }, [_vm._v(" " + _vm._s(g.name) + " ")]);
    })], 2)])]), _vm._l(grade.classroom, function (classroom, i2) {
      return _c('row-item', {
        key: i2
      }, [_c('p', {
        staticClass: "col_2"
      }), _c('p', {
        staticClass: "col_3"
      }, [_vm._v(" " + _vm._s(classroom.name) + " ")]), _c('p', {
        staticClass: "col_2"
      }, [_vm._v(_vm._s(classroom.studentCount) + "人")]), _c('p', {
        staticClass: "col_5"
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.newClassrooms[classroom.id].name,
          expression: "newClassrooms[classroom.id].name"
        }],
        staticClass: "common_form--input 8",
        attrs: {
          "type": "text",
          "placeholder": "次年度の組表記"
        },
        domProps: {
          "value": _vm.newClassrooms[classroom.id].name
        },
        on: {
          "change": function change($event) {
            return _vm.handleChangeName($event, _vm.newClassrooms[classroom.id]);
          },
          "input": function input($event) {
            if ($event.target.composing) return;

            _vm.$set(_vm.newClassrooms[classroom.id], "name", $event.target.value);
          }
        }
      })])]);
    })], 2);
  })], 2), _vm._l(_vm.error, function (e, i) {
    return _c('p', {
      key: i,
      staticClass: "error"
    }, [_vm._v(" " + _vm._s(e) + " ")]);
  }), _c('section', {
    staticClass: "common_form__button_wrap"
  }, [_c('input', {
    staticClass: "create_button",
    attrs: {
      "type": "button",
      "value": "保存する"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.handleSave.apply(null, arguments);
      }
    }
  })]), _vm.showModal ? _c('message-modal', {
    on: {
      "close": _vm.saved
    }
  }) : _vm._e()]], 2);
};

var staticRenderFns = [];
export { render, staticRenderFns };