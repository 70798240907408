import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy;

  return _c('span', [_c(_setup.GroupClassSelectForm, {
    attrs: {
      "is-loading": _setup.isLoading,
      "filter": _setup.filter
    },
    on: {
      "handleClassroomChange": _vm.handleClassroomChange,
      "handleGroupChange": _vm.handleGroupChange
    }
  }), _c('section', {
    staticClass: "calendar"
  }, [_c(_setup.CalendarHeading, {
    attrs: {
      "is-loading": _setup.isLoading,
      "filter": _setup.filter
    },
    on: {
      "init": _vm.init
    }
  }), _setup.schoolPlan.temperature ? _c(_setup.AttendanceTemperatureSwitch, {
    attrs: {
      "filter": _setup.filter
    }
  }) : _vm._e(), !_setup.isLoading && _setup.dekitusIds.length === 0 ? _c(_setup.DekitusIntroduction) : _vm._e(), !_setup.isLoading ? _c('div', {
    staticClass: "table__wrapper"
  }, _vm._l(_setup.visibleClassroom, function (classroom, i1) {
    return _c(_setup.MonthlyClassTable, {
      key: i1,
      ref: "classRef",
      refInFor: true,
      attrs: {
        "class-index": i1,
        "month-data": _setup.classMonthData[classroom.id],
        "daily-events": _setup.dailyEvents[classroom.id],
        "student-attendace-list": _setup.selectClassStudentAttendaceObject[classroom.id],
        "filter": _setup.filter,
        "classroom": classroom,
        "display-label": _setup.displayLabel
      }
    });
  }), 1) : _vm._e()], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };