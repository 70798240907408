import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { useRoute, useRouter } from "vue-router/composables";
import moment from "moment";
export default {
  __name: 'AttendanceTemperatureSwitch',
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props; // @ts-check

    var route = useRoute();
    var router = useRouter();

    var switchRoute = function switchRoute(mode) {
      var date = moment(props.filter.yearMonth);

      if (mode === "temperature") {
        router.push({
          name: "attendances.monthly",
          query: {
            classroom_id: props.filter.classroomId,
            group_id: props.filter.groupId,
            year_month: date.format("YYYY-MM"),
            temperature: "1"
          }
        });
      } else if (mode === "attendance") {
        router.push({
          name: "attendances.monthly",
          query: {
            classroom_id: props.filter.classroomId,
            group_id: props.filter.groupId,
            year_month: date.format("YYYY-MM")
          }
        });
      } else {
        router.push({
          name: "dekitus.monthly",
          query: {
            classroom_id: props.filter.classroomId,
            year_month: date.format("YYYY-MM")
          }
        });
      }
    };

    return {
      __sfc: true,
      route: route,
      router: router,
      props: props,
      switchRoute: switchRoute
    };
  }
};