import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import moment from "moment";
import MonthlyTableHead from "../monthly/MonthlyTableHead.vue";
import MonthlySchoolDay from "../monthly/MonthlySchoolDay.vue";
import MonthDailyEvent from "../monthly/MonthDailyEvent.vue";
import DailySummaryTable from "../monthly/DailySummaryTable.vue";
import MonthlyStudentTable from "../monthly/MonthlyStudentTable.vue";
import { showSumMinutes } from "@/services/common";

import { ref, computed } from 'vue';
import { useMonthlyYearlySummary } from '/Users/k-tanaka/project/137/cocoo_school_admin/src/composables/monthlyYearlySummary.js';
import { useDownloadAttendanceBook } from '/Users/k-tanaka/project/137/cocoo_school_admin/src/composables/downloadAttendanceBook.js';
export default {
  __name: 'MonthlyClassTable',
  props: {
    classIndex: {
      type: Number,
      required: true
    },
    monthData: {
      /** @type {PropType<MonthData[]>} */
      type: Array,
      required: true
    },
    dailyEvents: {
      type: Object,
      required: true
    },
    studentAttendaceList: {
      type: Array,
      required: true
    },
    filter: {
      /** @type {PropType<AttendanceFilter>} */
      type: Object,
      required: true
    },
    classroom: {
      type: Object,
      required: true
    },
    displayLabel: {
      type: String,
      default: ""
    }
  },
  emits: ["handleCommentEdit"],
  setup: function setup(__props, _ref) {
    var expose = _ref.expose,
        emit = _ref.emit;
    var props = __props; // @ts-check

    var dailySummary = ref(new Map([["日々出席人数", props.monthData.map(function () {
      return props.studentAttendaceList.length;
    })], ["日々欠席(病気)", props.monthData.map(function () {
      return 0;
    })], ["日々欠席(事故)", props.monthData.map(function () {
      return 0;
    })], ["停忌等", props.monthData.map(function () {
      return 0;
    })]]));

    var _useMonthlyYearlySumm = useMonthlyYearlySummary(props.monthData),
        schoolDayLength = _useMonthlyYearlySumm.schoolDayLength,
        getSummaryTemplate = _useMonthlyYearlySumm.getSummaryTemplate,
        aggregateStudentSummary = _useMonthlyYearlySumm.aggregateStudentSummary;

    var schoolDayBeforeYearMonthLength = computed(function () {
      var yearMonth = moment(props.filter.yearMonth).add(1, "month").startOf("month");
      var today = moment();

      if (today.isBefore(yearMonth)) {
        return props.monthData.filter(function (v) {
          return !v.isSchoolDayOff && today.isAfter(v["YYYY-MM-DD"]);
        }).length;
      } else {
        return props.monthData.filter(function (v) {
          return !v.isSchoolDayOff && yearMonth.isAfter(v["YYYY-MM-DD"]);
        }).length;
      }
    });
    var students = computed(function () {
      return props.studentAttendaceList.map(function (student) {
        var monthlySummary = getSummaryTemplate();
        monthlySummary["要出席日数"] = schoolDayLength.value;
        monthlySummary["出席日数"] = schoolDayBeforeYearMonthLength.value;
        var attendanceByDate = {};
        var dekitusByDate = {}; // @ts-expect-error

        student.attendance.forEach(function (v) {
          var dateNumber = moment(v.date).date() - 1;

          if (props.monthData[dateNumber].isSchoolDayOff) {
            return; // 休日のデータは無視する
          } // カレンダーの表示 (countingTypeはなくても表示する)


          attendanceByDate[v.date] = v;

          if (!v.counting_type) {
            return; // countingTypeがないときはSummaryに含めない
          }

          var countingType = v.counting_type.counting_type; // month

          aggregateStudentSummary(monthlySummary, countingType); // 1日ごとのクラス別合計

          if (countingType === "病気欠席") {
            dailySummary.value.get("日々欠席(病気)")[dateNumber]++;
            dailySummary.value.get("日々出席人数")[dateNumber]--;
          } else if (countingType === "事故欠席") {
            dailySummary.value.get("日々欠席(事故)")[dateNumber]++;
            dailySummary.value.get("日々出席人数")[dateNumber]--;
          } else if (countingType === "停忌等") {
            dailySummary.value.get("停忌等")[dateNumber]++;
            dailySummary.value.get("日々出席人数")[dateNumber]--;
          }
        }); // @ts-expect-error

        var dekitusByMonth = [];
        student.dekitus.forEach(function (v) {
          if (dekitusByDate[v.start_date]) {
            dekitusByDate[v.start_date].push(v.time);
          } else {
            dekitusByDate[v.start_date] = [v.time];
          }

          dekitusByMonth.push(v.time);
        });
        Object.keys(dekitusByDate).forEach(function (k) {
          var sum = showSumMinutes(dekitusByDate[k]);
          dekitusByDate[k] = sum;
        });

        if (dekitusByMonth.length > 0) {
          dekitusByMonth = showSumMinutes(dekitusByMonth);
        } else {
          dekitusByMonth = "";
        }

        return Object.assign(student, {
          attendanceByDate: attendanceByDate,
          monthlySummary: monthlySummary,
          dekitusByDate: dekitusByDate,
          dekitusByMonth: dekitusByMonth
        });
      });
    });

    var _useDownloadAttendanc = useDownloadAttendanceBook(props.filter, props.monthData, dailySummary.value, props.dailyEvents, students.value, props.classroom),
        downloadAttendanceBook = _useDownloadAttendanc.downloadAttendanceBook;

    var handleCommentEdit = function handleCommentEdit(v) {
      emit("handleCommentEdit", v);
    };

    expose({
      downloadAttendanceBook: downloadAttendanceBook
    });
    return {
      __sfc: true,
      props: props,
      emit: emit,
      dailySummary: dailySummary,
      schoolDayLength: schoolDayLength,
      getSummaryTemplate: getSummaryTemplate,
      aggregateStudentSummary: aggregateStudentSummary,
      schoolDayBeforeYearMonthLength: schoolDayBeforeYearMonthLength,
      students: students,
      downloadAttendanceBook: downloadAttendanceBook,
      handleCommentEdit: handleCommentEdit,
      MonthlyTableHead: MonthlyTableHead,
      MonthlySchoolDay: MonthlySchoolDay,
      MonthDailyEvent: MonthDailyEvent,
      DailySummaryTable: DailySummaryTable,
      MonthlyStudentTable: MonthlyStudentTable
    };
  }
};