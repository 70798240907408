import _objectSpread from "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { getData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import { mapState } from "vuex";
import RowItem from "../RowItem";
export default {
  name: "StudentSelector",
  components: {
    RowItem: RowItem,
    LoadingMessage: LoadingMessage
  },
  props: {
    sendTargets: {
      type: Object,
      default: function _default() {}
    },
    confirmMode: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isLoading: {
        status: false,
        message: "クラスを取得中"
      },
      filter: {
        keyword: "",
        attendance: [],
        classrooms: [],
        groups: []
      },
      classrooms: [],
      groups: [],
      showOnlySelected: false,
      showGroup: false,
      currentTab: "selectClassrooms",
      students: [],
      filteredStudents: [],
      selectedStudents: [],
      participants: [],
      filteredParticipants: [],
      selectedParticipants: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["grades", "studentAddress"])), {}, {
    hiddenStudentExists: function hiddenStudentExists() {
      return this.selectedStudents.length > this.filteredStudents.length;
    },
    classroomsCount: function classroomsCount() {
      var classroomsCount = 0;
      this.grades.forEach(function (v) {
        return classroomsCount += v.classroom.length;
      });
      return classroomsCount;
    },
    selectAllClassrooms: {
      get: function get() {
        return this.filter.classrooms.length === this.classroomsCount;
      },
      set: function set() {
        return this.filter.classrooms.length === this.classroomsCount;
      }
    },
    selectAllGroups: {
      get: function get() {
        return this.filter.groups.length === this.groups.length;
      },
      set: function set() {
        return this.filter.groups.length === this.groups.length;
      }
    },
    selectAllTargets: {
      get: function get() {
        return this.selectedStudents.length === this.filteredStudents.length && this.selectedParticipants.length === this.filteredParticipants.length;
      },
      set: function set() {
        return this.selectedStudents.length === this.filteredStudents.length && this.selectedParticipants.length === this.filteredParticipants.length;
      }
    },
    classroomGroups: function classroomGroups() {
      return this.groups.filter(function (v) {
        return v.type === "student" && v.class_id;
      });
    },
    studentGroups: function studentGroups() {
      return this.groups.filter(function (v) {
        return v.type === "student" && !v.class_id;
      });
    },
    participantGroups: function participantGroups() {
      return this.groups.filter(function (v) {
        return v.type === "participant";
      });
    },
    indeterminateClassrooms: function indeterminateClassrooms() {
      var _this = this;

      var indeterminateClassrooms = [];
      this.classrooms.forEach(function (v) {
        if (_this.selectedStudents.length > 0 && !_this.filter.classrooms.includes(v.id) && v.student.find(function (v) {
          return _this.selectedStudents.includes(v.id);
        })) {
          indeterminateClassrooms.push(v.id);
        }
      });
      return indeterminateClassrooms;
    }
  }),
  watch: {
    confirmMode: {
      handler: function handler(val) {
        var _this2 = this;

        if (val) {
          this.currentTab = "selectStudents";
          this.addMembers().then(function () {
            _this2.showOnlySelected = true;
          });
        }
      },
      immediate: true
    },
    "filter.classrooms": {
      handler: function handler(val, oldVal) {
        if (oldVal && val.length < oldVal.length) {
          var removedClassroomId = oldVal.find(function (v) {
            return !val.includes(v);
          });
          var c = this.classrooms.find(function (v) {
            return v.id === removedClassroomId;
          });

          if (c) {
            this.selectedStudents = this.selectedStudents.filter(function (v) {
              return !c.student.find(function (v2) {
                return v2.id === v;
              });
            });
          }
        }
      }
    }
  },
  mounted: function mounted() {
    var _this3 = this;

    //メッセージの編集の場合に送信先の選択状態を復帰
    if (this.$props.sendTargets.students) {
      this.selectedStudents = this.$props.sendTargets.students;
    }

    if (this.$props.sendTargets.participants) {
      this.selectedParticipants = this.$props.sendTargets.participants;
    }

    this.isLoading.status = true;
    this.isLoading.message = "クラスとグループを取得中";
    getData(["reader/get_classrooms_for_messenger", "reader/get_groups_for_messenger"]).then(function (data) {
      _this3.classrooms = data[0].items;

      if (_this3.selectedStudents.length > 0) {
        _this3.classrooms.forEach(function (v) {
          if (v.student.length > 0 && !v.student.find(function (v2) {
            return !_this3.selectedStudents.includes(v2.id);
          })) {
            _this3.filter.classrooms.push(v.id);
          }
        });
      }

      _this3.groups = data[1].items;
    }).catch(function (error) {
      console.log(error);
    }).then(function () {
      _this3.isLoading.status = false;
      _this3.isLoading.message = "";
    });
  },
  methods: {
    isIndeterminateClassroom: function isIndeterminateClassroom(classroomId) {
      return this.indeterminateClassrooms.includes(classroomId);
    },
    addMembers: function addMembers() {
      var _this4 = this;

      this.isLoading.status = true;
      this.isLoading.message = this.studentAddress + "を取得中";
      var studentQuery = "";
      var participantQuery = "";

      if (this.currentTab === "selectClassrooms" && this.filter.classrooms.length > 0) {
        this.filter.classrooms.forEach(function (v) {
          studentQuery += (studentQuery === "" ? "?" : "&") + "classrooms[]=" + v;
        });
        this.indeterminateClassrooms.forEach(function (v) {
          studentQuery += (studentQuery === "" ? "?" : "&") + "classrooms[]=" + v;
        });
      } else if (this.currentTab === "selectGroups" && this.filter.groups.length > 0) {
        this.filter.groups.forEach(function (v) {
          studentQuery += (studentQuery === "" ? "?" : "&") + "groups[]=" + v;
        });
        participantQuery += this.filter.groups.reduce(function (prev, current) {
          return prev + "&groups[]=" + current;
        }, "");
      }

      return getData(["reader/get_student_list_for_messenger" + studentQuery, "participants?with=group " + participantQuery]).then(function (data) {
        _this4.students = _this4.filteredStudents = data[0].items;
        _this4.participants = _this4.filteredParticipants = data[1].items;

        if (studentQuery || participantQuery) {
          if (studentQuery) {
            var selectedStudents = _this4.selectedStudents.slice();

            _this4.filteredStudents.forEach(function (v) {
              if (!selectedStudents.includes(v.id) && !_this4.indeterminateClassrooms.includes(v.classroom.id)) {
                selectedStudents.push(v.id);
              }
            });

            _this4.selectedStudents = selectedStudents;
          }

          if (participantQuery) {
            var selectedParticipants = _this4.selectedParticipants.slice();

            _this4.filteredParticipants.forEach(function (v) {
              if (!selectedParticipants.includes(v.id)) {
                selectedParticipants.push(v.id);
              }
            });

            _this4.selectedParticipants = selectedParticipants;
          }
        }
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this4.isLoading.status = false;
      });
    },
    changeAll: function changeAll(e, model) {
      if (model === "classroom") {
        var selected = [];

        if (e.target.checked) {
          this.grades.forEach(function (g) {
            g.classroom.forEach(function (v) {
              selected.push(v.id);
            });
          });
        }

        this.filter.classrooms = selected;
      }

      if (model === "group") {
        if (e.target.checked) {
          this.filter.groups = this.groups.map(function (v) {
            return v.id;
          });
        } else {
          this.filter.groups = [];
        }
      }

      if (model === "target") {
        if (e.target.checked) {
          this.selectedStudents = this.filteredStudents.map(function (v) {
            return v.id;
          });
          this.selectedParticipants = this.filteredParticipants.map(function (v) {
            return v.id;
          });
        } else {
          this.selectedStudents = [];
          this.selectedParticipants = [];
        }
      }
    },
    filterStudents: function filterStudents() {
      var students = this.students;
      var participants = this.participants;
      var keyword = this.filter.keyword;
      var attendance = this.filter.attendance;

      if (keyword) {
        keyword = keyword.trim().split(/[\x20\u3000]+/);
        students = students.filter(function (v) {
          return v.name.indexOf(keyword) > -1;
        });
        participants = participants.filter(function (v) {
          return v.name.indexOf(keyword) > -1;
        });
      }

      if (attendance.length > 0) {
        students = students.filter(function (v) {
          return attendance.find(function (v2) {
            return v.attendance[0] && v.attendance[0].type.indexOf(v2) > -1;
          });
        });
      }

      this.filteredStudents = students;
      this.filteredParticipants = participants;
    },
    showSelectedMembers: function showSelectedMembers() {
      this.addMembers();
      this.currentTab = "selectStudents";
    },
    saveTargets: function saveTargets() {
      this.filter.classrooms = [];
      this.filter.groups = [];
      this.$emit("update:selectedClassrooms", this.filter.classrooms);
      this.$emit("update:sendTargets", {
        students: this.selectedStudents,
        participants: this.selectedParticipants
      });
      this.$emit("close");
    },
    divition: function divition(index, denominator) {
      return index % denominator === 0;
    }
  }
};