import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.map.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_questionnaire_data status"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("アンケート詳細 - 回答状況")]), _c('page-switch', {
    attrs: {
      "message-id": _vm.enquete.message_id,
      "active": "status",
      "message-link": "messages.status"
    }
  }), _c('div', {
    staticClass: "button_wrap"
  }, [_c('section', {
    staticClass: "switch_button"
  }, [_c('a', {
    class: 'switch_button__left item ' + (_vm.to === 'students' ? 'active' : ''),
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.handleTo('students');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.studentAddress) + " ")]), _c('a', {
    class: 'switch_button__right item ' + (_vm.to === 'participants' ? 'active' : ''),
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.handleTo('participants');
      }
    }
  }, [_vm._v(" 関係者 ")])])]), _c('form', {
    staticClass: "gray_frame",
    attrs: {
      "method": "get"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.filterList.apply(null, arguments);
      }
    }
  }, [_c('row-item', [_c('label', {
    staticClass: "col_2",
    attrs: {
      "for": "statusDD"
    }
  }, [_vm._v("ステータス")]), _c('div', {
    staticClass: "col_2"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterOptions.status,
      expression: "filterOptions.status"
    }],
    staticClass: "common_form--select",
    attrs: {
      "id": "statusDD",
      "disabled": _vm.isLoading.statuses
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.filterOptions, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("すべて")]), _c('option', {
    attrs: {
      "value": "unanswered"
    }
  }, [_vm._v("未回答")]), _c('option', {
    attrs: {
      "value": "answered"
    }
  }, [_vm._v("回答済")])])]), _c('div', {
    staticClass: "col_6"
  }, [_c('row-item', [_c('label', {
    staticClass: "col_2",
    attrs: {
      "for": "filterKeyword"
    }
  }, [_vm._v("氏名")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filterOptions.keyword,
      expression: "filterOptions.keyword"
    }],
    staticClass: "common_form--input col_8",
    attrs: {
      "id": "filterKeyword",
      "type": "text",
      "disabled": _vm.isLoading.statuses
    },
    domProps: {
      "value": _vm.filterOptions.keyword
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.filterOptions, "keyword", $event.target.value);
      }
    }
  })])], 1), _c('div', {
    staticClass: "col_2 tar"
  }, [_c('input', {
    staticClass: "submit_button",
    attrs: {
      "type": "submit",
      "value": "検索",
      "disabled": _vm.isLoading.statuses
    }
  })])])], 1), _c('form', {
    staticClass: "common_form send_list",
    attrs: {
      "action": "",
      "method": "post"
    }
  }, [_c('h2', [_vm._v(" アンケートの名前: "), _c('span', {
    staticClass: "enquete_subject"
  }, [_vm._v(_vm._s(_vm.enquete.subject))])]), _c('row-item', {
    staticClass: "description"
  }, [_c('div', {
    staticClass: "col_6"
  }, [_vm.isLoading.statuses ? _c('p', [_vm._v("関係者回答率 0% (0 / 0)")]) : _c('p', [_vm._v(" " + _vm._s(_vm.to === "students" ? "生徒回答率" : "関係者回答率") + " " + _vm._s(_vm.statuses.length ? Math.round(_vm.answeredCnt / _vm.statuses.length * 1000) / 10 : 0) + "% (" + _vm._s(_vm.answeredCnt) + " / " + _vm._s(_vm.statuses.length) + ") ")])]), _c('div', {
    staticClass: "icons col_6 tar"
  }, [_c('p', {
    staticClass: "icons__item display--inline_block"
  }, [_c('i', {
    staticClass: "far fa-envelope"
  }), _vm._v("メール ")]), _c('p', {
    staticClass: "icons__item display--inline_block"
  }, [_c('i', {
    staticClass: "fas fa-phone"
  }), _vm._v("電話 ")])])]), _c('div', {
    staticClass: "questionnaire_table"
  }, [_c('row-item', {
    staticClass: "questionnaire_table__heading"
  }, [_c('p', {
    staticClass: "col_1"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checkAllFlag,
      expression: "checkAllFlag"
    }],
    staticClass: "common_form--checkbox",
    attrs: {
      "id": "check_all",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.checkAllFlag) ? _vm._i(_vm.checkAllFlag, null) > -1 : _vm.checkAllFlag
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.checkAllFlag,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.checkAllFlag = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.checkAllFlag = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.checkAllFlag = $$c;
        }
      }, _vm.checkAll]
    }
  }), _c('label', {
    staticClass: "common_form--label",
    attrs: {
      "for": "check_all"
    }
  })]), _c('p', {
    staticClass: "col_3"
  }, [_vm._v(" 氏名(" + _vm._s(_vm.to === "students" ? "クラス" : "所属など") + ") ")]), _c('p', {
    staticClass: "col_1"
  }, [_vm._v("種別")]), _c('p', {
    staticClass: "col_2"
  }, [_vm._v("ステータス")]), _c('p', {
    staticClass: "col_3"
  }, [_vm._v("回答日時")]), _c('p', {
    staticClass: "col_2"
  })]), _vm.isLoading.statuses ? _c('div', {
    staticClass: "questionnaire_table__content"
  }, _vm._l(5, function (n) {
    return _c('row-item', {
      key: n
    }, [_c('div', {
      staticClass: "col_12"
    }, [_c('content-placeholders', [_c('content-placeholders-text', {
      attrs: {
        "lines": 1
      }
    })], 1)], 1)]);
  }), 1) : _vm.filteredStatuses.length === 0 ? _c('div', {
    staticClass: "questionnaire_table__content"
  }, [_c('row-item', [_c('div', {
    staticClass: "col_12"
  }, [_vm._v("条件に合う対象者が見つかりません。")])])], 1) : _vm.to === 'students' ? _vm._l(_vm.filteredStatuses, function (status, index) {
    return _c('div', {
      key: index,
      staticClass: "questionnaire_table__content"
    }, [_c('row-item', [_c('div', {
      staticClass: "col_1"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.reSendTargets,
        expression: "reSendTargets"
      }],
      staticClass: "common_form--checkbox",
      attrs: {
        "id": 'student_' + status.id,
        "type": "checkbox",
        "name": "all_select"
      },
      domProps: {
        "value": status.id,
        "checked": Array.isArray(_vm.reSendTargets) ? _vm._i(_vm.reSendTargets, status.id) > -1 : _vm.reSendTargets
      },
      on: {
        "change": [function ($event) {
          var $$a = _vm.reSendTargets,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = status.id,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.reSendTargets = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.reSendTargets = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.reSendTargets = $$c;
          }
        }, _vm.testAllChecked]
      }
    }), _c('label', {
      staticClass: "common_form--label",
      attrs: {
        "for": 'student_' + status.id
      }
    })]), _c('p', {
      staticClass: "col_3"
    }, [_vm._v(" " + _vm._s(status.classroom ? status.classroom.name : "") + " " + _vm._s(_vm._f("shorten")(status.name)) + " ")]), _c('p', {
      staticClass: "col_1"
    }, [status.guardian ? _c('span', {
      staticClass: "detail_button",
      on: {
        "click": function click($event) {
          return _vm.showDetail(status.id);
        }
      }
    }, [_vm._v("詳細")]) : _vm._e()])]), _vm._l(status.guardian, function (guardian) {
      return _c('row-item', {
        key: guardian.id
      }, [_c('div', {
        staticClass: "col_1"
      }), _c('p', {
        staticClass: "col_3"
      }, [_vm._v(" " + _vm._s(_vm._f("shorten")(guardian.name)) + " " + _vm._s(guardian.relationship ? "（" + guardian.relationship + "）" : "") + " ")]), _c('p', {
        staticClass: "col_1"
      }, [status.type === 'email' ? _c('span', [_c('i', {
        staticClass: "far fa-envelope"
      })]) : _c('span', [_c('i', {
        staticClass: "fas fa-phone"
      })])]), _c('p', {
        staticClass: "col_2"
      }, [status.answer.length > 0 ? _c('span', [_vm._v("回答済")]) : _c('span', {
        staticClass: "icon--red"
      }, [_vm._v("未回答")])]), _c('p', {
        staticClass: "col_5"
      }, [_vm._v(" " + _vm._s(status.answer.length > 0 ? _vm.getJPTime(status.answer[0].updated_at) : "") + " ")])]);
    })], 2);
  }) : _vm.to === 'participants' ? _vm._l(_vm.filteredStatuses, function (status, i) {
    return _c('div', {
      key: i,
      staticClass: "questionnaire_table__content"
    }, [_c('row-item', [_c('div', {
      staticClass: "col_1"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.reSendTargets,
        expression: "reSendTargets"
      }],
      staticClass: "common_form--checkbox",
      attrs: {
        "id": 'participant_' + status.id,
        "type": "checkbox",
        "name": "all_select"
      },
      domProps: {
        "value": status.id,
        "checked": Array.isArray(_vm.reSendTargets) ? _vm._i(_vm.reSendTargets, status.id) > -1 : _vm.reSendTargets
      },
      on: {
        "change": [function ($event) {
          var $$a = _vm.reSendTargets,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = status.id,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.reSendTargets = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.reSendTargets = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.reSendTargets = $$c;
          }
        }, _vm.testAllChecked]
      }
    }), _c('label', {
      staticClass: "common_form--label",
      attrs: {
        "for": 'participant_' + status.id
      }
    })]), _c('p', {
      staticClass: "col_3"
    }, [_vm._v(" " + _vm._s(status.belonging ? status.belonging + " " : "") + " " + _vm._s(_vm._f("shorten")(status.name)) + " ")]), _c('p', {
      staticClass: "col_1"
    }, [status.type === 'email' ? _c('span', [_c('i', {
      staticClass: "far fa-envelope"
    })]) : _c('span', [_c('i', {
      staticClass: "fas fa-phone"
    })])]), _c('p', {
      staticClass: "col_2"
    }, [status.answer.length > 0 ? _c('span', [_vm._v("回答済")]) : _c('span', {
      staticClass: "icon--red"
    }, [_vm._v("未回答")])]), _c('p', {
      staticClass: "col_3"
    }, [_vm._v(" " + _vm._s(status.answer.length > 0 ? _vm.getJPTime(status.answer[0].updated_at) : "") + " ")]), _c('p', {
      staticClass: "col_2"
    }, [status.answer.length > 0 ? _c('span', {
      staticClass: "detail_button",
      on: {
        "click": function click($event) {
          return _vm.showDetail(status.id);
        }
      }
    }, [_vm._v("詳細")]) : _vm._e()])])], 1);
  }) : _vm._e()], 2), _c('div', {
    staticClass: "under_button"
  }, [_c('span', {
    staticClass: "arrow"
  }), _c('button', {
    staticClass: "create_button",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.handleReSendButton.apply(null, arguments);
      }
    }
  }, [_vm._v(" 選択した" + _vm._s(_vm.to === "students" ? _vm.studentAddress : "関係者") + "にお知らせ作成 ")])]), _c('div', {
    staticClass: "common_form__button_wrap"
  }, [_c('router-link', {
    staticClass: "return_button",
    attrs: {
      "to": {
        name: 'enquetes'
      }
    }
  }, [_vm._v(" 一覧に戻る ")])], 1)], 1), _vm.showModal ? _c('section', {
    staticClass: "common_modal"
  }, [_c('div', {
    staticClass: "common_modal__inner"
  }, [_c('div', {
    staticClass: "common_modal__white_frame",
    attrs: {
      "id": "targetFrame"
    }
  }, [_c('div', {
    staticClass: "common_modal__inner_frame",
    attrs: {
      "id": "modal_questionnaireAnswer"
    }
  }, [_c('h4', {
    staticClass: "common_modal__title"
  }, [_vm._v(" " + _vm._s(_vm.modalStatus.target.name) + " ")]), _c('div', {
    staticClass: "questionnaire_table"
  }, [_c('row-item', {
    staticClass: "questionnaire_table__content"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("アンケートの名前")]), _c('p', {
    staticClass: "col_9 large_text"
  }, [_vm._v(" " + _vm._s(_vm.enquete.subject) + " ")])])], 1), _vm._l(_vm.modalStatus.questions, function (question, i) {
    return _c('div', {
      key: i
    }, [_c('div', {
      staticClass: "questionnaire_subject"
    }, [_vm._v(" 質問 " + _vm._s(question.subject) + " ")]), _vm._l(question.answer, function (answer, i2) {
      return _c('div', {
        key: i2,
        staticClass: "questionnaire_table"
      }, [_c('row-item', {
        staticClass: "questionnaire_table__content"
      }, [_c('p', {
        staticClass: "col_3"
      }, [_vm._v("回答")]), _c('p', {
        staticClass: "col_9"
      }, [_vm._v(" " + _vm._s(_vm.getAnswer(answer, question)) + " ")])]), _c('row-item', {
        staticClass: "questionnaire_table__content"
      }, [_c('p', {
        staticClass: "col_3"
      }, [_vm._v("回答日時")]), _c('p', {
        staticClass: "col_9"
      }, [_vm._v(" " + _vm._s(_vm.getAnsweredTime(answer)) + " ")])])], 1);
    })], 2);
  }), _c('section', {
    staticClass: "common_form__button_wrap"
  }, [_c('p', {
    staticClass: "return_button",
    on: {
      "click": function click($event) {
        _vm.showModal = false;
      }
    }
  }, [_vm._v("一覧に戻る")])])], 2)])])]) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };