import _objectSpread from "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.constructor.js";
import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.typed-array.to-reversed.js";
import "core-js/modules/esnext.typed-array.to-sorted.js";
import "core-js/modules/esnext.typed-array.with.js";
import moment from "moment";
import { getData } from "@/services/axios";
import { mapState } from "vuex";
import { saveAs } from "file-saver";
import RowItem from "../RowItem";
import PageSwitch from "./PageSwitch";
import Papa from "papaparse";
export default {
  name: "EnqueteResult",
  components: {
    RowItem: RowItem,
    PageSwitch: PageSwitch
  },
  data: function data() {
    return {
      to: "students",
      enquete: {},
      questions: [],
      answers: [],
      studentAnswers: [],
      participantAnswers: [],
      studentTargetCount: 0,
      participantTargetCount: 0,
      isLoading: true,
      isProcessing: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["studentAddress"])), {}, {
    inProgress: function inProgress() {
      return moment().startOf("day").isBefore(moment(this.enquete.implement_to).startOf("day"));
    },
    targetCount: function targetCount() {
      return this.to === "students" ? this.studentTargetCount : this.participantTargetCount;
    }
  }),
  watch: {
    to: function to() {
      this.answers = this.to === "students" ? this.studentAnswers : this.participantAnswers;
    }
  },
  created: function created() {
    this.setData();
  },
  methods: {
    setData: function setData() {
      var _this = this;

      getData(["enquetes/progress/" + this.$route.params.id, "questions?locale=ja-JP&enquete_id=" + this.$route.params.id, "reader/get_answers/" + this.$route.params.id]).then(function (data) {
        if (data[0] && data[1] && data[2]) {
          _this.enquete = data[0].items;
          _this.questions = data[1].items.map(function (v) {
            if (v.question_contents_i18n) {
              return {
                id: v.id,
                subject: v.question_contents_i18n[0].subject,
                type: v.type,
                options: v.question_contents_i18n[0].options
              };
            }
          });
          _this.answers = _this.studentAnswers = data[2].items.answers.students;
          _this.participantAnswers = data[2].items.answers.participants;
          _this.studentTargetCount = data[2].items.target_count.students;
          _this.participantTargetCount = data[2].items.target_count.participants;
        }
      }).catch(function (error) {
        console.log(error); // axiosのresponseが存在するエラーの時は一覧に戻る

        if (error.response) {
          _this.$router.push({
            name: "enquetes"
          });
        }
      }).then(function () {
        _this.isLoading = false;
      });
    },
    getDateJP: function getDateJP(datetime) {
      return moment(datetime).format("YYYY年MM月DD日 HH:mm");
    },
    getAnswerSummary: function getAnswerSummary(question_id, answer_index) {
      var answers = this.answers.find(function (v) {
        return v[0] && v[0].question_id === question_id;
      });
      return answers ? answers.filter(function (v) {
        return parseInt(v.body) === answer_index;
      }).length : 0;
    },
    getAnswerGraph: function getAnswerGraph(question_id, answer_index) {
      return this.targetCount > 0 ? Math.floor(this.getAnswerSummary(question_id, answer_index) / this.targetCount * 100) : 0;
    },
    getAnswerRate: function getAnswerRate() {
      //すべての質問には均等に回答されているとみなして、１つ目で回答率を算出
      return this.answers[0] && this.answers[0].length && this.targetCount ? Math.round(this.answers[0].length / this.targetCount * 1000) / 10 : 0;
    },
    getResultCSV: function getResultCSV() {
      var _this2 = this;

      var enquete_id = this.$route.params.id;
      var output_filename = "アンケート結果【" + this.enquete.subject.replace(/[/\\?%*:|"<>]/g, "-") + "】（" + (this.to === "students" ? this.studentAddress : "関係者") + "）_" + moment().format("YYYYMMDD_HHmm") + "時点.csv";
      this.isProcessing = true;
      getData("reader/get_enquete_result_csv/" + enquete_id + "?to=" + this.to, {
        responseType: "blob"
      }).then(function (response) {
        var data = response.items;
        var csv = [[data.title]].concat([data.headers], data.contents);
        var bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        saveAs(new Blob([bom, Papa.unparse(csv)], {
          type: "text/csv;charset=utf-8"
        }), output_filename);
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this2.isProcessing = false;
      });
    },
    handleTo: function handleTo(val) {
      this.to = val;
    }
  }
};