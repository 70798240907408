export const dashboardTableList = {
  firstLineHeader: [
    {
      label: "生徒数",
      rowspan: 3,
      colspan: 1,
    },
    {
      label: "欠席",
      rowspan: 2,
      colspan: 2,
    },
    {
      label: "",
      rowspan: 1,
      colspan: 3,
    },
    {
      label: "病欠",
      rowspan: 1,
      colspan: 16,
    },
    {
      label: "出席停止",
      rowspan: 1,
      colspan: 16,
    },
    {
      label: "事",
      rowspan: 1,
      colspan: 1,
    },
    {
      label: "忌",
      rowspan: 1,
      colspan: 1,
    },
    {
      label: "事",
      rowspan: 1,
      colspan: 2,
    },
    {
      label: "病欠",
      rowspan: 1,
      colspan: 2,
    },
    {
      label: "事故欠",
      rowspan: 1,
      colspan: 5,
    },
  ],
  secondLineHeader: [
    {
      label: "遅刻早退",
      rowspan: 1,
      colspan: 3,
    },
    {
      label: "病気やケガ",
      rowspan: 1,
      colspan: 10,
    },
    {
      label: "体調不良",
      rowspan: 1,
      colspan: 6,
    },
    {
      label: "感染症",
      rowspan: 1,
      colspan: 16,
    },
    {
      label: "介護・家の手伝い",
      rowspan: 2,
      colspan: 1,
    },
    {
      label: "忌引",
      rowspan: 2,
      colspan: 1,
    },
    {
      label: "家の都合",
      rowspan: 2,
      colspan: 1,
    },
    {
      label: "その他",
      rowspan: 2,
      colspan: 1,
    },
    {
      label: "特別支援校",
      rowspan: 1,
      colspan: 7,
    },
  ],
  thirdLineHeader: [
    {
      label: "合計",
    },
    {
      label: "37.5度以上",
    },
    {
      label: "遅刻",
    },
    {
      label: "早退",
    },
    {
      label: "給食不要",
    },
    {
      label: "風邪",
    },
    {
      label: "発熱",
    },
    {
      label: "頭痛",
    },
    {
      label: "腹痛",
    },
    {
      label: "嘔吐下痢",
    },
    {
      label: "咳・息苦しさ",
    },
    {
      label: "受診・通院",
    },
    {
      label: "発疹",
    },
    {
      label: "ケガ",
    },
    {
      label: "その他",
    },
    {
      label: "気分がすぐれない",
    },
    {
      label: "疲れ・倦怠感など",
    },
    {
      label: "ストレスや悩み・不安感",
    },
    {
      label: "吐き気",
    },
    {
      label: "生理痛",
    },
    {
      label: "その他",
    },
    {
      label: "新型コロナ診断",
    },
    {
      label: "インフルエンザ",
    },
    {
      label: "感染性胃腸炎",
    },
    {
      label: "溶連菌",
    },
    {
      label: "水ぼうそう",
    },
    {
      label: "おたふくかぜ",
    },
    {
      label: "ふうしん",
    },
    {
      label: "はしか（麻しん）",
    },
    {
      label: "百日咳",
    },
    {
      label: "結核",
    },
    {
      label: "マイコプラズマ感染症",
    },
    {
      label: "りんご病（伝染性紅班）",
    },
    {
      label: "手足口病",
    },
    {
      label: "プール熱（咽頭結膜熱）",
    },
    {
      label: "流行性角結膜炎",
    },
    {
      label: "その他",
    },
    {
      label: "受診",
    },
    {
      label: "術後訓練",
    },
    {
      label: "リハビリ訓練",
    },
    {
      label: "定期通院",
    },
    {
      label: "入所",
    },
    {
      label: "レスパイト",
    },
    {
      label: "その他",
    },
  ],
  key: [
    {
      label: "total",
      percent: true,
    },
    {
      label: "37.5度以上",
    },
    {
      label: "遅刻",
    },
    {
      label: "早退",
    },
    {
      label: "給食不要",
    },
    {
      label: "欠席-風邪",
    },
    {
      label: "欠席-発熱",
    },
    {
      label: "欠席-頭痛",
    },
    {
      label: "欠席-腹痛",
    },
    {
      label: "欠席-嘔吐下痢",
    },
    {
      label: "欠席-咳・息苦しさ",
    },
    {
      label: "欠席-受診・通院",
    },
    {
      label: "欠席-発疹",
    },
    {
      label: "欠席-ケガ",
    },
    {
      label: "欠席-その他病気",
    },
    {
      label: "欠席-体調不良-気分がすぐれない",
    },
    {
      label: "欠席-体調不良-疲れ倦怠感",
    },
    {
      label: "欠席-体調不良-ストレス悩み不安感",
    },
    {
      label: "欠席-体調不良-吐き気",
    },
    {
      label: "欠席-体調不良-生理痛",
    },
    {
      label: "欠席-体調不良-その他",
    },
    {
      label: "欠席-コロナ-診断",
      percent: true,
    },
    {
      label: "欠席-感染症-インフルエンザ",
      percent: true,
    },
    {
      label: "欠席-感染症-感染性胃腸炎",
      percent: true,
    },
    {
      label: "欠席-感染症-溶連菌",
      percent: true,
    },
    {
      label: "欠席-感染症-水ぼうそう",
      percent: true,
    },
    {
      label: "欠席-感染症-おたふく",
      percent: true,
    },
    {
      label: "欠席-感染症-ふうしん",
      percent: true,
    },
    {
      label: "欠席-感染症-はしか",
      percent: true,
    },
    {
      label: "欠席-感染症-百日咳",
      percent: true,
    },
    {
      label: "欠席-感染症-結核",
      percent: true,
    },
    {
      label: "欠席-感染症-マイコプラズマ",
      percent: true,
    },
    {
      label: "欠席-感染症-りんご病",
      percent: true,
    },
    {
      label: "欠席-感染症-手足口病",
      percent: true,
    },
    {
      label: "欠席-感染症-プール熱",
      percent: true,
    },
    {
      label: "欠席-感染症-流行性角結膜炎",
      percent: true,
    },
    {
      label: "欠席-感染症-その他",
      percent: true,
    },
    {
      label: "欠席-介護・手伝い",
    },
    {
      label: "欠席-忌引",
    },
    {
      label: "欠席-家の都合",
    },
    {
      label: "欠席-その他",
    },
    {
      label: "欠席-特支-受診",
    },
    {
      label: "欠席-特支-術後訓練",
    },
    {
      label: "欠席-特支-リハビリ訓練",
    },
    {
      label: "欠席-特支-定期通院",
    },
    {
      label: "欠席-特支-入所",
    },
    {
      label: "欠席-特支-レスパイト",
    },
    {
      label: "欠席-特支-その他",
    },
  ],
};
