import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import { attendanceSelection } from "@/consts/attendanceSelection";
import RowItem from "../RowItem";
export default {
  components: {
    RowItem: RowItem
  },
  props: {
    type: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      attendanceSelectionList: attendanceSelection,
      attendanceSelectionChildren: [],
      isShowSetTypeButton: false
    };
  },
  methods: {
    setSelectedType: function setSelectedType(item, depth) {
      this.attendanceSelectionChildren = this.attendanceSelectionChildren.slice(0, depth);
      this.attendanceSelectionChildren.push(item);
      this.isShowSetTypeButton = !item.children;
    },
    setType: function setType() {
      var typeStr = this.attendanceSelectionChildren.filter(function (v) {
        return v.value;
      }).map(function (w) {
        return w.value;
      }).join("-");
      this.$emit("setType", typeStr);
    },
    resetType: function resetType() {
      var typeSplit = this.type.split("-");
      this.$emit("resetType"); // 変更を押す前の状態を表示する

      this.attendanceSelectionChildren = [];
      var children = this.attendanceSelectionList;

      while (children) {
        var item = this.findBeforeSelectedType(children, typeSplit);

        if (item) {
          this.attendanceSelectionChildren.push(item);
        }

        children = item.children;
      }

      this.isShowSetTypeButton = true;
    },
    findBeforeSelectedType: function findBeforeSelectedType(selectionList, typeSplit) {
      var item = selectionList.find(function (v) {
        return v.value === typeSplit[0];
      });

      if (item) {
        // 普通に見つかったとき
        typeSplit.shift();
        return item;
      } // 見つからないときはvalueが空文字のものを選択したと仮定する


      var nullValueItem = selectionList.find(function (v) {
        return v.value === "";
      });

      if (nullValueItem) {
        return nullValueItem;
      } // 遅刻早退の時間の部分のみsplitで分割されてうまく見つけられないので次の要素をjoinした状態で確認する


      if (typeSplit[1]) {
        var lateLeaveTiming = selectionList.find(function (v) {
          return v.value === "".concat(typeSplit[0], "-").concat(typeSplit[1]);
        });

        if (lateLeaveTiming) {
          typeSplit.splice(0, 2);
          return lateLeaveTiming;
        }
      }
    }
  }
};