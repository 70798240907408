import _objectSpread from "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { mapState } from "vuex";
import { getData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import RowItem from "../RowItem";
export default {
  name: "SelectStudent",
  components: {
    RowItem: RowItem,
    LoadingMessage: LoadingMessage
  },
  data: function data() {
    return {
      students: [],
      filteredStudents: [],
      selectedClassroom: false,
      selectedStudent: {},
      filterKeyword: "",
      isFetching: false
    };
  },
  head: {
    title: function title() {
      return {
        inner: this.pageTitle
      };
    },
    meta: []
  },
  computed: _objectSpread(_objectSpread({}, mapState(["grades", "classrooms", "studentAddress"])), {}, {
    pageTitle: function pageTitle() {
      return "欠席登録 - " + this.studentAddress + "選択";
    }
  }),
  created: function created() {
    this.setData();
  },
  methods: {
    setData: function setData() {},
    fetchStudents: function fetchStudents() {
      var _this = this;

      this.isFetching = true;
      this.students = [];
      this.selectedStudent = {};
      getData("students?classrooms[]=" + this.selectedClassroom).then(function (data) {
        _this.students = _this.filteredStudents = data.items;
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this.isFetching = false;
      });
    },
    filterStudents: function filterStudents() {
      var students = this.students;
      var keyword = this.filterKeyword;

      if (keyword) {
        students = students.filter(function (v) {
          return v.name.indexOf(keyword) > -1 || v.ruby.indexOf(keyword) > -1;
        });
      }

      this.selectedStudent = {};
      this.filteredStudents = students;
    }
  }
};