/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=a93766a4&scoped=true"
import script from "./Index.vue?vue&type=script&setup=true&lang=js"
export * from "./Index.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Index.vue?vue&type=style&index=0&id=a93766a4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a93766a4",
  null
  
)

export default component.exports