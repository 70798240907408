import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.map.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('main', {
    staticClass: "main page_students_create"
  }, [_c('h1', {
    staticClass: "title--page"
  }, [_vm._v("関係者情報" + _vm._s(_vm.id ? "更新" : "作成"))]), _vm.isLoading.getData ? _c('loading-message') : _vm._e(), _vm.isLoading.postData ? _c('loading-message', {
    attrs: {
      "loading-message": _vm.loadingMessage
    }
  }) : _vm._e(), _c('form', {
    staticClass: "common_form",
    attrs: {
      "action": "",
      "method": "post"
    }
  }, [_c('section', {
    staticClass: "gray_frame"
  }, [_c('p', {
    staticClass: "gray_frame__heading"
  }, [_vm._v("関係者の設定")]), _c('div', {
    staticClass: "border_frame"
  }, [_c('row-item', {
    staticClass: "start item"
  }, [_c('p', {
    staticClass: "required col_3"
  }, [_vm._v("関係者氏名")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.participant.name,
      expression: "participant.name"
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text",
      "placeholder": "山田 花子"
    },
    domProps: {
      "value": _vm.participant.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.participant, "name", $event.target.value);
      }
    }
  }), _vm.error.name ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.name) + " ")]) : _vm._e()])]), _c('row-item', {
    staticClass: "start item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("ふりがな")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.participant.ruby,
      expression: "participant.ruby"
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text",
      "placeholder": "やまだ はなこ"
    },
    domProps: {
      "value": _vm.participant.ruby
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.participant, "ruby", $event.target.value);
      }
    }
  }), _vm.error.ruby ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.ruby) + " ")]) : _vm._e()])]), _c('row-item', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("所属")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.participant.belonging,
      expression: "participant.belonging"
    }],
    staticClass: "common_form--input col_5",
    attrs: {
      "type": "text",
      "placeholder": "地域ボランティア"
    },
    domProps: {
      "value": _vm.participant.belonging
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.participant, "belonging", $event.target.value);
      }
    }
  })])]), _c('row-item', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("言語")]), _c('div', {
    staticClass: "col_9"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.participant.locale,
      expression: "participant.locale"
    }],
    staticClass: "common_form--select col_4",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.participant, "locale", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "ja-JP"
    }
  }, [_vm._v("日本語")]), _c('option', {
    attrs: {
      "value": "en-US"
    }
  }, [_vm._v("English")]), _c('option', {
    attrs: {
      "value": "es-US"
    }
  }, [_vm._v("Español")]), _c('option', {
    attrs: {
      "value": "zh-CN"
    }
  }, [_vm._v("中国語（簡体字）")])])])]), _c('row-item', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("メールアドレス")]), _c('div', {
    staticClass: "col_9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.participant.email,
      expression: "participant.email"
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text",
      "placeholder": "sample@example.com"
    },
    domProps: {
      "value": _vm.participant.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.participant, "email", $event.target.value);
      }
    }
  }), _vm.error.email ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.email) + " ")]) : _vm._e()])]), _c('row-item', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("電話番号")]), _c('div', {
    staticClass: "col_7"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.participant.phone,
      expression: "participant.phone"
    }],
    staticClass: "common_form--input col_12",
    attrs: {
      "type": "text",
      "placeholder": "09012345678"
    },
    domProps: {
      "value": _vm.participant.phone
    },
    on: {
      "blur": function blur($event) {
        $event.preventDefault();
        return _vm.checkPhoneExists.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.participant, "phone", $event.target.value);
      }
    }
  }), _vm.error.phone ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_vm.error.phone) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "common_form--editor col_2"
  }, [_c('div', {
    staticStyle: {
      "padding": "10px 5px"
    }
  }, [_vm.isLoading.phoneExists ? _c('p', [_vm._v("確認中")]) : _vm._e(), _vm.isNewNumber ? _c('p', [_c('i', {
    staticClass: "fas fa-info-circle"
  }), _vm._v("OK")]) : _vm._e()])])]), _c('row-item', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("お知らせ方法")]), _c('div', {
    staticClass: "col_9"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.participant.normal_receive_method,
      expression: "participant.normal_receive_method"
    }],
    staticClass: "common_form--select",
    attrs: {
      "name": "contact_type"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.participant, "normal_receive_method", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "email"
    }
  }, [_vm._v("メール")]), _c('option', {
    attrs: {
      "value": "phone"
    }
  }, [_vm._v("電話")])])])]), _vm.capabilities.enableEmergencyCall ? _c('row-item', {
    staticClass: "item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("緊急のお知らせ")]), _c('div', {
    staticClass: "col_9"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.participant.emergency_receive_method,
      expression: "participant.emergency_receive_method"
    }],
    staticClass: "common_form--select",
    attrs: {
      "name": "contact_type"
    },
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });

        _vm.$set(_vm.participant, "emergency_receive_method", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "both"
    }
  }, [_vm._v("メールと電話")]), _c('option', {
    attrs: {
      "value": "email"
    }
  }, [_vm._v("メール")]), _c('option', {
    attrs: {
      "value": "phone"
    }
  }, [_vm._v("電話")])])])]) : _vm._e(), _c('row-item', {
    staticClass: "start item"
  }, [_c('p', {
    staticClass: "col_3"
  }, [_vm._v("メモ")]), _c('div', {
    staticClass: "col_9"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.participant.memo,
      expression: "participant.memo"
    }],
    staticClass: "common_form--textarea",
    attrs: {
      "id": "memo",
      "name": "memo",
      "cols": "30",
      "rows": "10"
    },
    domProps: {
      "value": _vm.participant.memo
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;

        _vm.$set(_vm.participant, "memo", $event.target.value);
      }
    }
  })])])], 1)]), _c('section', {
    staticClass: "tac"
  }, [_c('input', {
    staticClass: "cancel_button",
    attrs: {
      "type": "button",
      "value": "キャンセル"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }), _vm.id ? _c('input', {
    staticClass: "update_button",
    attrs: {
      "type": "button",
      "value": "更新する"
    },
    on: {
      "click": _vm.saveData
    }
  }) : _c('input', {
    staticClass: "register_button",
    attrs: {
      "type": "button",
      "value": "登録する"
    },
    on: {
      "click": _vm.saveData
    }
  }), _vm.id ? _c('button', {
    staticClass: "delete_button",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.confirmDelete.apply(null, arguments);
      }
    }
  }, [_vm._v(" 削除する ")]) : _vm._e()])]), _vm.showModal ? _c('message-modal', {
    on: {
      "close": _vm.modalClosed
    }
  }) : _vm._e(), _vm.showConfirmModal ? _c('confirm-modal', {
    attrs: {
      "confirm-message": _vm.confirmMessage,
      "confirm-message-title": _vm.confirmMessageTitle
    },
    on: {
      "clickOK": _vm.removeData,
      "clickCancel": _vm.cancelConfirm
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };