import _objectSpread from "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.constructor.js";
import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import "core-js/modules/esnext.typed-array.to-reversed.js";
import "core-js/modules/esnext.typed-array.to-sorted.js";
import "core-js/modules/esnext.typed-array.with.js";
import "core-js/modules/web.dom-collections.for-each.js";
import store from "../../services/store.js";
import { mapState } from "vuex";
import { getData, createData } from "@/services/axios";
import MessageModal from "../common/MessageModal.vue";
import LoadingMessage from "@/components/common/LoadingMessage";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import Encoding from "encoding-japanese";
import emailValidator from "email-validator";
import RowItem from "../RowItem";
export default {
  store: store,
  name: "TeacherCSVUpload",
  components: {
    MessageModal: MessageModal,
    LoadingMessage: LoadingMessage,
    RowItem: RowItem
  },
  data: function data() {
    return {
      csv: [],
      emails: [],
      skipEmails: [],
      notice: [],
      error: [],
      tableError: [],
      isLoading: false,
      showModal: false,
      section1: false,
      section2: false,
      section3: false,
      notifyRegistered: true
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["classrooms", "me"])), {}, {
    entryCount: function entryCount() {
      return this.csv.length - 1 - this.skipEmails.length;
    }
  }),
  created: function created() {
    if (this.$store.state.me.role_id > 11) {
      this.$router.push({
        name: "dashboard"
      });
    }
  },
  methods: {
    downloadTemplateCSV: function downloadTemplateCSV() {
      var bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      saveAs(new Blob([bom, Papa.unparse([["氏名", "メールアドレス", "権限", "学年組"]])], {
        type: "text/csv;charset=utf-8"
      }), "teacher_template.csv");
    },
    setAttachment: function setAttachment(e) {
      var _this = this;

      var csvFile = e.target.files[0];
      if (!csvFile) return;
      this.error = [];
      this.tableError = [];
      this.notice = [];

      if (!csvFile.name.endsWith(".csv")) {
        this.error.push("ファイルの種類が不正です。CSVをアップロードしてください");
      }

      if (csvFile.size > 2097152) {
        this.error.push("ファイルサイズが5MBを超えています。添付できるCSVは5MB以下です。");
      }

      if (this.error.length > 0) return;
      var reader = new FileReader();

      reader.onload = function (e) {
        var codes = new Uint8Array(e.target.result);
        var encoding = Encoding.detect(codes); // SJISの検知は間違う場合があるので、確実にUTF8でなければSJISとする

        var from = encoding === "UTF8" ? "UTF8" : "SJIS";
        var csvString = Encoding.convert(codes, {
          to: "UNICODE",
          from: from,
          type: "string"
        }); //UTF-8 with BOMのときにBOM除去

        if (csvString.charCodeAt(0) === 0xfeff) {
          csvString = csvString.substr(1);
        }

        var csv = Papa.parse(csvString);

        var cleaned = _this.csvDataCleaning(csv);

        _this.csv = cleaned.data;
        _this.emails = cleaned.emails;

        if (_this.emails.length > 0) {
          _this.checkEmailsExists();
        } //同名ファイルの再アップロードを検知するためにリセット


        document.getElementById("file").value = "";
      };

      reader.readAsArrayBuffer(csvFile);
    },
    csvDataCleaning: function csvDataCleaning(csv) {
      var _this2 = this;

      //エラーテーブルを初期化
      csv.data.forEach(function (v) {
        var row = [];
        v.forEach(function () {
          return row.push(false);
        });

        _this2.tableError.push(row);
      });

      if (csv.errors.length > 0) {
        this.error = csv.errors.map(function (v) {
          return v.message;
        });
      }

      var data = [];
      var emails = [];
      csv.data.forEach(function (row, i) {
        if (i === 0) {
          if (row.join() !== "氏名,メールアドレス,権限,学年組") {
            _this2.error.push("見出し行が「氏名」「メールアドレス」「権限」「学年組」と異なります");

            _this2.tableError[i] = _this2.tableError[i].map(function () {
              return true;
            });
          }

          return data.push(row);
        }

        if (row.length !== 4) {
          //ExcelのCSVは最終行に空行を作るので許容する
          if (!(row.length === 1 && i === csv.data.length - 1)) {
            _this2.error.push(i + 1 + "行目の値が4列ではありません");

            _this2.tableError[i] = _this2.tableError[i].map(function () {
              return true;
            });
            return data.push(row);
          } else {
            return;
          }
        }

        row = row.map(function (cell, j) {
          cell = Encoding.toHankakuCase(cell).trim();
          var valid = true;

          if (j === 0) {
            if (!cell) {
              _this2.error.push(i + 1 + "行目の氏名を入力してください");

              valid = false;
            } else if (!cell.match(/\s/)) {
              _this2.error.push(i + 1 + "行目の氏名の姓と名の間に半角スペースを入れてください");

              valid = false;
            }

            cell = cell.replace(/\s+/, " ");
          }

          if (j === 1) {
            if (!cell) {
              _this2.error.push(i + 1 + "行目のメールアドレスを入力してください");

              emails.push("");
              valid = false;
            } else if (!emailValidator.validate(cell)) {
              _this2.error.push(i + 1 + "行目のメールアドレスの形式が正しくありません");

              emails.push("");
              valid = false;
            } else {
              emails.push(cell);
            }
          }

          if (j === 3) {
            if (cell && !_this2.classrooms.find(function (c) {
              return c.name === cell;
            })) {
              _this2.error.push(i + 1 + "行目の学年組はCOCOOに登録されている学年組(クラス名)と異なります");

              valid = false;
            }
          }

          if (!valid) _this2.tableError[i][j] = true;
          return cell;
        });
        data.push(row);
      });
      return {
        data: data,
        emails: emails
      };
    },
    checkEmailsExists: function checkEmailsExists() {
      var _this3 = this;

      var duplicateEmail = this.emails.filter(function (x, i, self) {
        return self.indexOf(x) === i && i !== self.lastIndexOf(x);
      });

      if (duplicateEmail.length > 0) {
        this.error.push("CSVの中で " + duplicateEmail.join(", ") + "のメールアドレスが重複しています。");
      }

      this.isLoading = true;
      var params = "?emails[]=" + this.emails.map(function (v) {
        return encodeURIComponent(v);
      }).reduce(function (accumulator, currentValue) {
        return accumulator + "&emails[]=" + currentValue;
      });
      return getData("reader/get_emails_duplicate" + params).then(function (data) {
        _this3.isLoading = false;

        if (data.items.length > 0) {
          var skipEmails = [];
          data.items.forEach(function (v) {
            if (v.school === "other") {
              _this3.error.push(v.email + "は他校で登録済みのメールアドレスです。COCOOサポート事務局にお問い合わせください");
            } else {
              _this3.notice.push(v.email + "はこの学校に既存の先生のメールアドレスです。この行は登録をスキップされます。");

              skipEmails.push(v.email);
            }
          });
          _this3.skipEmails = skipEmails;
        }
      }).catch(function (error) {
        console.log(error);
        _this3.isLoading = false;
      });
    },
    saveData: function saveData() {
      var _this4 = this;

      this.isLoading = true;
      var classroomsMap = {};
      this.classrooms.forEach(function (v) {
        return classroomsMap[v.name] = v.id;
      });
      var teachers = []; //ヘッダー行の除去

      this.csv.shift();
      this.csv.forEach(function (v) {
        if (!_this4.skipEmails.find(function (w) {
          return w === v[1];
        })) {
          teachers.push({
            name: v[0],
            email: v[1],
            role_id: v[2] === "管理者" ? 11 : 12,
            classrooms: classroomsMap[v[3]] ? [classroomsMap[v[3]]] : []
          });
        }
      });
      createData("writer/bulk_write_teacher", {
        teachers: teachers,
        notifyRegistered: this.notifyRegistered
      }).then(function () {
        _this4.$store.dispatch("commitModalMessage", {
          message: "先生情報一括登録が完了しました",
          title: "登録完了"
        });

        _this4.showModal = true;
        _this4.isLoading = false;
      }).catch(function (error) {
        _this4.$store.dispatch("commitModalMessage", {
          message: "先生情報一括登録に失敗しました",
          title: "登録失敗"
        });

        console.log(error);
        _this4.showModal = true;
        _this4.isLoading = false;
      });
    },
    completeCreate: function completeCreate() {
      this.showModal = false;
      this.$router.push({
        name: "teachers"
      });
    },
    open: function open(value) {
      if (value === "section1") {
        this.section1 = true;
      } else if (value === "section2") {
        this.section2 = true;
      } else if (value === "section3") {
        this.section3 = true;
      }
    },
    close: function close(value) {
      if (value === "section1") {
        this.section1 = false;
      } else if (value === "section2") {
        this.section2 = false;
      } else if (value === "section3") {
        this.section3 = false;
      }
    }
  }
};