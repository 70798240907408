import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import moment from "moment";
import MessageModal from "../common/MessageModal.vue";
import { getData, createData, updateData, deleteData } from "@/services/axios";
export default {
  name: "MonthlyComment",
  components: {
    MessageModal: MessageModal
  },
  filters: {
    localYearMonth: function localYearMonth(v) {
      return moment(v).format("YYYY年MM月");
    }
  },
  props: {
    student: {
      type: Object,
      default: function _default() {}
    },
    yearMonth: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      showModal: false,
      comment: "",
      attendanceMonthlyComment: {},
      comments: []
    };
  },
  created: function created() {
    this.getComments();
  },
  methods: {
    getComments: function getComments() {
      var _this = this;

      getData("attendance_monthly_comments?student_id=" + this.student.id).then(function (data) {
        _this.comments = data.items;

        if (_this.comments.find(function (v) {
          return v.start_of_month === moment(_this.yearMonth).format("YYYY-MM-DD");
        })) {
          _this.attendanceMonthlyComment = _this.comments.find(function (v) {
            return v.start_of_month === moment(_this.yearMonth).format("YYYY-MM-DD");
          });
          _this.comment = _this.attendanceMonthlyComment.comment;
        }

        console.log(data.items);
      }).catch(function (error) {
        console.log(error);
      });
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;

      var successMessage, successTitle, failMessage, failTitle;
      new Promise(function (resolve, reject) {
        if (!_this2.attendanceMonthlyComment.id) {
          successTitle = "登録完了";
          successMessage = "コメントを登録しました";
          failTitle = "登録失敗";
          failMessage = "コメントを登録できませんでした";
          createData("attendance_monthly_comments", {
            student_id: _this2.student.id,
            comment: _this2.comment,
            start_of_month: moment(_this2.yearMonth).startOf("month").format("YYYY-MM-DD")
          }).then(function (v) {
            return resolve(v);
          }).catch(function (v) {
            return reject(v);
          });
        } else {
          successTitle = "更新完了";
          successMessage = "コメントを更新しました";
          failTitle = "更新失敗";
          failMessage = "コメントを更新できませんでした";
          updateData("attendance_monthly_comments/" + _this2.attendanceMonthlyComment.id, {
            student_id: _this2.student.id,
            comment: _this2.comment,
            start_of_month: moment(_this2.yearMonth).startOf("month").format("YYYY-MM-DD")
          }).then(function (v) {
            return resolve(v);
          }).catch(function (v) {
            return reject(v);
          });
        }
      }).then(function () {
        _this2.$store.dispatch("commitModalMessage", {
          message: successMessage,
          title: successTitle
        });
      }).catch(function (error) {
        _this2.$store.dispatch("commitModalMessage", {
          message: failMessage,
          title: failTitle
        });

        console.log(error);
      }).finally(function () {
        _this2.showModal = true;
      });
    },
    handleDelete: function handleDelete() {
      var _this3 = this;

      deleteData("attendance_monthly_comments/" + this.attendanceMonthlyComment.id).then(function (data) {
        console.log(data.items);

        _this3.$store.dispatch("commitModalMessage", {
          message: "コメントを削除しました",
          title: "削除完了"
        });
      }).catch(function (error) {
        _this3.$store.dispatch("commitModalMessage", {
          message: "コメントを削除できませんでした",
          title: "削除失敗"
        });

        console.log(error);
      }).finally(function () {
        _this3.showModal = true;
      });
    },
    closeModal: function closeModal() {
      this.showModal = false;
      this.$emit("close-modal");
      this.getComments();
    }
  }
};