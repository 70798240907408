import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.string.includes.js";
// @ts-check
import moment from "moment";
import { useStore } from "vue2-helpers/vuex";
export var useMonthlySummary = function useMonthlySummary() {
  var _Number, _Number2;

  var route = useRoute();
  var store = useStore();
  /** @type Ref<AttendanceFilter> */

  var filter = ref({
    classroomId: route.query.classroom_id ? (_Number = Number(route.query.classroom_id)) !== null && _Number !== void 0 ? _Number : 0 : 0,
    groupId: route.query.group_id ? (_Number2 = Number(route.query.group_id)) !== null && _Number2 !== void 0 ? _Number2 : 0 : 0,
    yearMonth: route.query.year_month ? String(route.query.year_month) : moment().format("YYYY-MM")
  });

  var updateFilter = function updateFilter() {
    var _Number3, _Number4;

    filter.value.classroomId = route.query.classroom_id ? (_Number3 = Number(route.query.classroom_id)) !== null && _Number3 !== void 0 ? _Number3 : 0 : 0;
    filter.value.groupId = route.query.group_id ? (_Number4 = Number(route.query.group_id)) !== null && _Number4 !== void 0 ? _Number4 : 0 : 0;
    filter.value.yearMonth = route.query.year_month ? String(route.query.year_month) : moment().format("YYYY-MM");
  };

  watch(route, function () {
    updateFilter();
  });
  /** @type ComputedRef<string[]> */

  var holidays = computed(function () {
    return store.state.holidays;
  });
  /**
   * 1か月の日ごとの情報
   *
   * @type ComputedRef<MonthData[]>
   */

  var monthData = computed(function () {
    var monthDataTemp = [];
    var fdOm = moment(filter.value.yearMonth).startOf("month");
    var days = fdOm.daysInMonth();

    for (var i = 0; i < days; i++) {
      monthDataTemp.push({
        "YYYY-MM-DD": fdOm.format("YYYY-MM-DD"),
        d: fdOm.format("d"),
        D: fdOm.format("D"),
        isHoliday: holidays.value.includes(fdOm.format("YYYY-MM-DD")),
        weekName: fdOm.format("ddd"),
        isSchoolDayOff: false
      });
      monthDataTemp[i].isSchoolDayOff = monthDataTemp[i].isHoliday || monthDataTemp[i].d === "0" || monthDataTemp[i].d === "6";
      fdOm.add(1, "d");
    }

    return monthDataTemp;
  });
  return {
    filter: filter,
    monthData: monthData
  };
};