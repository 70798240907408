import _objectSpread from "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import Vue from "vue";
import RowItem from "../RowItem";
import MessageModal from "../common/MessageModal.vue";
import ConfirmModal from "@/components/common/ConfirmModal";
import LoadingMessage from "@/components/common/LoadingMessage";
import { mapState } from "vuex";
import { getData, createData, updateData, deleteData as _deleteData } from "@/services/axios";
import moment from "moment";
export default {
  name: "Edit",
  components: {
    RowItem: RowItem,
    MessageModal: MessageModal,
    LoadingMessage: LoadingMessage,
    ConfirmModal: ConfirmModal
  },
  data: function data() {
    return {
      id: null,
      showModal: false,
      showConfirmModal: false,
      confirmMessageTitle: "",
      confirmMessage: "",
      modalAction: "save",
      isLoading: false,
      error: {
        title: "",
        from: "",
        to: "",
        eventClassroom: ""
      },
      allCheck: false,
      isAllDay: true,
      title: "",
      from: null,
      to: null,
      isSchoolDayOff: 0,
      eventClassroom: [],
      selectedGrades: [],
      sendMessage: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["grades", "classrooms", "me"])), {}, {
    filteredClassrooms: function filteredClassrooms() {
      var _this = this;

      var classrooms = [];
      this.grades.forEach(function (v) {
        if (_this.selectedGrades.includes(v.id)) {
          v.classroom.forEach(function (v2) {
            if (!classrooms.find(function (v3) {
              return v3.id === v2.id;
            })) {
              classrooms.push(v2);
            }
          });
        }
      });
      return classrooms;
    }
  }),
  watch: {
    from: function from(val) {
      if (val && (!this.to || this.from > this.to)) {
        this.to = moment(this.from).add(1, "hour").format("YYYY-MM-DD HH:mm:ss");
      }
    },
    allCheck: function allCheck(val) {
      if (val) {
        this.eventClassroom = this.classrooms.map(function (v) {
          return v.id;
        });
        this.selectedGrades = this.grades.map(function (v) {
          return v.id;
        });
      }
    },
    eventClassroom: function eventClassroom(val) {
      this.allCheck = val.length === this.classrooms.length;
    },
    selectedGrades: function selectedGrades(currentGrades, previousGrades) {
      if (currentGrades.length > previousGrades.length) {
        // 新しく追加された学年を見つける
        var newGrade = currentGrades.find(function (v) {
          return !previousGrades.includes(v);
        });
        var grade = this.grades.find(function (v) {
          return v.id === newGrade;
        });

        if (grade) {
          var gradeClassroom = grade.classroom;
          var gradeClassroomIds = gradeClassroom.map(function (v) {
            return v.id;
          });
          this.eventClassroom = this.eventClassroom.concat(gradeClassroomIds).filter(function (elem, index, self) {
            return self.indexOf(elem) === index;
          });
        }
      }
    },
    filteredClassrooms: function filteredClassrooms(val, oldVal) {
      if (val.length < oldVal.length) {
        this.eventClassroom = this.eventClassroom.filter(function (v) {
          return val.find(function (v2) {
            return v2.id === v;
          });
        });
      }
    }
  },
  created: function created() {
    var _this2 = this;

    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      getData("events/" + this.$route.params.id + "?with=classroom").then(function (data) {
        var v = data.items[0];
        _this2.title = v.title;
        _this2.from = v.from;
        _this2.to = v.to;
        _this2.isSchoolDayOff = v.is_school_day_off;
        _this2.isAllDay = v.is_all_day;
        _this2.eventClassroom = v.classroom.map(function (v) {
          return v.classroom_id;
        });
        var selectedGrades = [];

        _this2.grades.forEach(function (v2) {
          if (v2.classroom.find(function (v3) {
            return _this2.eventClassroom.includes(v3.id);
          })) {
            selectedGrades.push(v2.id);
          }
        });

        _this2.selectedGrades = selectedGrades;
        Vue.nextTick().then(function () {
          // selectedGradesのwatchを待ってから正しいクラスで上書き
          _this2.eventClassroom = v.classroom.map(function (v) {
            return v.classroom_id;
          });
        });
      });
    }
  },
  methods: {
    validate: function validate() {
      var valid = true;
      this.error = {
        title: "",
        from: "",
        to: "",
        eventClassroom: ""
      };

      if (!this.from) {
        this.error.from = "開始日時を入力してください。";
        valid = false;
      }

      if (!this.to) {
        this.error.to = "終了日時を入力してください。";
        valid = false;
      }

      if (!this.title) {
        this.error.title = "タイトルを入力してください。";
        valid = false;
      }

      if (this.title && this.title.length > 200) {
        this.error.title = "タイトルは200文字以内で入力してください";
        valid = false;
      }

      if (this.eventClassroom.length === 0) {
        this.error.eventClassroom = "対象クラスを入力してください。";
        valid = false;
      }

      return valid;
    },
    saveEvent: function saveEvent() {
      var _this3 = this;

      var valid = this.validate();

      if (!valid) {
        return;
      }

      this.isLoading = true;

      if (this.$route.params.id) {
        this.loadingMessage = "年間予定を更新しています。";
        updateData("writer/write_event/" + this.$route.params.id, {
          title: this.title,
          from: this.from,
          to: this.to,
          is_school_day_off: this.isSchoolDayOff,
          is_all_day: this.isAllDay,
          event_classroom: this.eventClassroom
        }).then(function (data) {
          _this3.id = data.id;

          _this3.$store.dispatch("commitModalMessage", {
            message: "年間予定更新が完了しました",
            title: "更新完了"
          });

          _this3.showModal = true;
        }).catch(function () {
          _this3.$store.dispatch("commitModalMessage", {
            message: "年間予定更新に失敗しました。",
            title: "エラー"
          });

          _this3.showModal = true;
        }).then(function () {
          _this3.showModal = true;
          _this3.isLoading = false;
        });
      } else {
        this.loadingMessage = "年間予定を登録しています。";
        createData("writer/write_event", {
          title: this.title,
          from: this.from,
          to: this.to,
          is_school_day_off: this.isSchoolDayOff,
          is_all_day: this.isAllDay,
          event_classroom: this.eventClassroom
        }).then(function (data) {
          _this3.id = data.id;

          _this3.$store.dispatch("commitModalMessage", {
            message: "年間予定登録が完了しました",
            title: "登録完了"
          });

          _this3.showModal = true;
        }).catch(function () {
          _this3.$store.dispatch("commitModalMessage", {
            message: "年間予定登録に失敗しました。",
            title: "エラー"
          });

          _this3.showModal = true;
        }).then(function () {
          _this3.showModal = true;
          _this3.isLoading = false;
        });
      }
    },
    confirmCreate: function confirmCreate() {
      var valid = this.validate();

      if (valid) {
        this.modalAction = "save";
        this.confirmMessageTitle = "イベント登録";
        this.confirmMessage = moment(this.from).format("YYYY年MM月DD日(ddd)") + (moment(this.from).startOf("day").isSame(moment(this.to).startOf("day")) ? "" : "から" + moment(this.to).format("YYYY年MM月DD日(ddd)")) + "は" + (this.isSchoolDayOff ? "休日" : "授業日") + "となります。\nよろしいですか？";
        this.showConfirmModal = true;
      }
    },
    confirmDelete: function confirmDelete() {
      this.modalAction = "delete";
      this.confirmMessageTitle = "イベント削除";
      this.confirmMessage = "イベントを削除します。\n本当によろしいですか？";
      this.showConfirmModal = true;
    },
    cancelConfirm: function cancelConfirm() {
      this.showConfirmModal = false;
    },
    handleModalAction: function handleModalAction() {
      if (this.modalAction === "delete") {
        this.deleteData();
      } else {
        this.saveEvent();
      }

      this.showConfirmModal = false;
    },
    deleteData: function deleteData() {
      var _this4 = this;

      this.isLoading = true;
      this.showConfirmModal = false;

      _deleteData("eraser/delete_event/" + this.$route.params.id).then(function () {
        _this4.$store.dispatch("commitModalMessage", {
          message: "イベント削除が完了しました",
          title: "削除完了"
        });
      }).catch(function () {
        _this4.$store.dispatch("commitModalMessage", {
          message: "イベント削除に失敗しました",
          title: "削除失敗"
        });
      }).then(function () {
        _this4.showModal = true;
      });
    },
    completeAction: function completeAction() {
      var _this5 = this;

      this.showModal = false;

      if (this.sendMessage) {
        this.isLoading = true;
        var formattedDate = "";

        if (this.isAllDay) {
          formattedDate = moment(this.from).format("MM月DD日(ddd)");

          if (moment(this.from).format("YYYY-MM-DD") !== moment(this.to).format("YYYY-MM-DD")) {
            formattedDate += " - " + moment(this.to).format("MM月DD日(ddd)");
          }
        } else {
          formattedDate = moment(this.from).format("MM月DD日(ddd) HH:mm");

          if (moment(this.from).format("YYYY-MM-DD") === moment(this.to).format("YYYY-MM-DD")) {
            formattedDate += " - " + moment(this.to).format("HH:mm");
          } else {
            formattedDate += " - " + moment(this.to).format("MM月DD日(ddd) HH:mm");
          }
        }

        var subject = formattedDate + " " + this.title;
        var body = "".concat(this.me.name, "\u5148\u751F\u304B\u3089\u4E88\u5B9A\u304C\u767B\u9332\u3055\u308C\u307E\u3057\u305F\u3002\n").concat(subject, "\n\nCOCOO\u4FDD\u8B77\u8005\u30B5\u30A4\u30C8\u304B\u3089\u30AB\u30EC\u30F3\u30C0\u30FC\u3092\u78BA\u8A8D\u3057\u3066\u304F\u3060\u3055\u3044\u3002\n");
        var studentQuery = "";
        this.eventClassroom.forEach(function (v) {
          studentQuery += (studentQuery === "" ? "?" : "&") + "classrooms[]=" + v;
        });
        getData("reader/get_student_list_for_messenger" + studentQuery).then(function (data) {
          _this5.isLoading = false;

          if (data.items) {
            _this5.$router.push({
              name: "messages.create",
              params: {
                targets: {
                  students: data.items.map(function (v) {
                    return v.id;
                  }),
                  participants: []
                },
                content: {
                  subject: subject,
                  call_body: body,
                  mail_body: body
                }
              }
            });
          }
        });
      } else {
        this.$router.push({
          name: "events"
        });
      }
    },
    handleAllCheck: function handleAllCheck() {
      if (!this.allCheck) {
        this.eventClassroom = [];
        this.selectedGrades = [];
      }
    }
  }
};