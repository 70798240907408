import _objectSpread from "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import moment from "moment";
import { getData } from "@/services/axios";
import { mapState } from "vuex";
import RowItem from "../RowItem";
import PageSwitch from "./PageSwitch";
export default {
  name: "Status",
  components: {
    RowItem: RowItem,
    PageSwitch: PageSwitch
  },
  data: function data() {
    return {
      to: "students",
      enquete: {},
      isLoading: {
        enquete: true,
        statuses: true
      },
      reSendTargets: [],
      checkAllFlag: false,
      filterOptions: {
        status: false,
        keyword: ""
      },
      showModal: false,
      title: "",
      statuses: [],
      studentStatuses: [],
      participantStatuses: [],
      filteredStatuses: [],
      modalStatus: {}
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["classrooms", "studentAddress"])), {}, {
    answeredCnt: function answeredCnt() {
      return this.statuses.filter(function (v) {
        return v.answer.length > 0;
      }).length;
    },
    sendTargetsParam: function sendTargetsParam() {
      return this.to === "students" ? {
        students: this.reSendTargets,
        participants: []
      } : {
        students: [],
        participants: this.reSendTargets
      };
    }
  }),
  watch: {
    to: function to() {
      this.filterList();
    }
  },
  created: function created() {
    this.setData();
  },
  methods: {
    setData: function setData() {
      var _this = this;

      getData("enquetes/progress/" + this.$route.params.id).then(function (data) {
        _this.enquete = data.items;
      }).catch(function (error) {
        console.log(error); // axiosのresponseが存在するエラーの時は一覧に戻る

        if (error.response && _this.$route.name !== "enquetes") {
          _this.$router.push({
            name: "enquetes"
          });
        }
      }).then(function () {
        _this.isLoading.enquete = false;
      });
      getData("reader/get_enquete_statuses/" + this.$route.params.id).then(function (data) {
        if (data) {
          _this.statuses = _this.studentStatuses = _this.filteredStatuses = data.items.students.map(function (v) {
            var firstGuardianId = v.guardian[0] ? v.guardian[0].id : null;
            v.type = "email";

            if (firstGuardianId) {
              var messageStatus = v.guardian_student.find(function (v2) {
                return v2.id === firstGuardianId;
              });

              if (messageStatus) {
                v.type = messageStatus.type;
              }
            }

            return v;
          });
          _this.participantStatuses = data.items.participants.map(function (v) {
            v.type = v.participant_message_status && v.participant_message_status[0] ? v.participant_message_status[0].type : "email";
            return v;
          });
        }
      }).catch(function (error) {
        console.log(error); // axiosのresponseが存在するエラーの時は一覧に戻る
        // if (error.response && this.$route.name !== "enquetes") {
        //   this.$router.push({ name: "enquetes" });
        // }
      }).then(function () {
        _this.isLoading.statuses = false;
      });
    },
    showDetail: function showDetail(target_id) {
      var _this2 = this;

      var target = this.to === "students" ? "student" : "participant";
      getData("reader/get_enquete_answers_by_target?enquete_id=" + this.$route.params.id + "&target_id=" + target_id + "&target=" + target).then(function (data) {
        if (data) {
          var v = data.items;
          v.questions = v.questions.map(function (v2) {
            v2.subject = "";
            v2.options = [];

            if (v2.question_contents_i18n && v2.question_contents_i18n[0]) {
              var questionContents = v2.question_contents_i18n[0];
              v2.subject = questionContents.subject;

              if (questionContents.options) {
                v2.options = questionContents.options;
              }

              v2.subject = questionContents.subject;

              if (target === "participant") {
                v2.answer = v2.participant_answer;
              }
            }

            return v2;
          });
          _this2.modalStatus = v;
        }
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this2.showModal = true;
      });
    },
    handleReSendButton: function handleReSendButton() {
      var _this3 = this;

      if (this.enquete.message_id) {
        getData("reader/get_message_detail/" + this.enquete.message_id).then(function (data) {
          var message_detail = data.items;

          _this3.$router.push({
            name: "messages.create",
            params: {
              targets: _this3.sendTargetsParam,
              content: {
                subject: message_detail.content.subject,
                call_body: message_detail.content.call_body,
                mail_body: message_detail.content.mail_body,
                attached_file: message_detail.message.attached_file
              },
              enquete_subject: message_detail.enquete_contents && message_detail.enquete_contents.subject ? message_detail.enquete_contents.subject : "",
              questions: message_detail.questions
            }
          });
        }).catch(function (error) {
          console.log(error);
        });
      }
    },
    getJPTime: function getJPTime(datetime) {
      return moment(datetime).isValid() ? moment(datetime).format("llll") : "";
    },
    getAnsweredTime: function getAnsweredTime(answer) {
      var created_at = answer.created_at;
      return moment(created_at).isValid() ? moment(created_at).format("llll") : "";
    },
    getAnswer: function getAnswer(answer, question) {
      if (!answer.body) return "（未回答）";
      return question.type === "single" ? question.options[answer.body] : answer.body;
    },
    checkAll: function checkAll() {
      if (this.checkAllFlag) {
        if (this.to === "students") {
          this.reSendTargets = this.filteredStatuses.map(function (v) {
            return v.id;
          });
        } else {
          this.reSendTargets = this.filteredStatuses.map(function (v) {
            return v.participant.id;
          });
        }
      } else {
        this.reSendTargets = [];
      }
    },
    // すべてチェック用チェックボックスの監視
    testAllChecked: function testAllChecked() {
      this.checkAllFlag = this.statuses.map(function (v) {
        return v.id;
      }).length === this.reSendTargets.length;
    },
    filterList: function filterList() {
      var statuses = this.statuses = this.to === "students" ? this.studentStatuses : this.participantStatuses;
      var status = this.filterOptions.status;
      var keyword = this.filterOptions.keyword;

      if (status) {
        statuses = statuses.filter(function (v) {
          var flag = v.answer.length > 0;
          return status === "answered" ? flag : !flag;
        });
      }

      if (keyword !== "") {
        if (this.to === "students") {
          statuses = statuses.filter(function (v) {
            return v.name.indexOf(keyword) > -1 || v.ruby.indexOf(keyword) > -1 || v.guardian.findIndex(function (v2) {
              return v2.name.indexOf(keyword) > -1;
            }) > -1 || v.guardian.findIndex(function (v2) {
              return v2.ruby.indexOf(keyword) > -1;
            }) > -1;
          });
        } else {
          statuses = statuses.filter(function (v) {
            return v.name.indexOf(keyword) > -1 || v.belonging && v.belonging.indexOf(keyword) > -1;
          });
        }
      }

      this.filteredStatuses = statuses;
    },
    handleTo: function handleTo(val) {
      this.to = val;
    }
  }
};