import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.constructor.js";
import { ref } from "vue";
import { useRouter } from "vue-router/composables";
import moment from "moment";
import ConfirmSlotModal from "@/components/common/ConfirmSlotModal.vue";
import RowItem from "../RowItem.vue";
export default {
  __name: 'ConfirmParticipantQr',
  props: {
    clickCancel: {
      type: Function,
      required: true
    }
  },
  setup: function setup(__props) {
    // @ts-check
    var router = useRouter();
    var belong = ref("");
    var number = ref("");

    var moveGeneratePage = function moveGeneratePage() {
      var num = Number(number.value);

      if (num && num > 0 && num <= 100) {
        router.push({
          name: "participants.qr_generate",
          params: {
            belong: belong.value,
            number: number.value
          }
        });
      }
    };

    return {
      __sfc: true,
      router: router,
      belong: belong,
      number: number,
      moveGeneratePage: moveGeneratePage,
      moment: moment,
      ConfirmSlotModal: ConfirmSlotModal,
      RowItem: RowItem
    };
  }
};