import _objectSpread from "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import "core-js/modules/esnext.iterator.some.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { mapState } from "vuex";
import { getData, getDataByPostMethod } from "@/services/axios";
import ConfirmModal from "@/components/common/ConfirmModal";
import RowItem from "../RowItem";
import moment from "moment";
export default {
  name: "StudentsIndex",
  components: {
    RowItem: RowItem,
    ConfirmModal: ConfirmModal
  },
  data: function data() {
    return {
      isLoading: true,
      selectedClass: false,
      showModal: false,
      students: [],
      filteredStudents: [],
      guardiansLimit: process.env.VUE_APP_GUARDIANS_LIMIT,
      filterParam: {
        keyword: "",
        classroom: false,
        hasGuardian: false
      },
      showConfirmModal: false,
      confirmMessageTitle: "",
      confirmMessage: "",
      targetStudentIds: [],
      numberOfStudents: 0,
      hasGuardian: 0,
      registrationRate: 0
    };
  },
  head: {
    title: function title() {
      return {
        inner: "全校一覧"
      };
    },
    meta: []
  },
  computed: _objectSpread({}, mapState(["grade", "classrooms", "studentAddress", "me"])),
  created: function created() {
    this.setData();
  },
  methods: {
    setData: function setData() {
      var _this = this;

      getData("students?with[]=guardian&with[]=classroom").then(function (data) {
        _this.students = _this.filteredStudents = data.items;

        _this.calcRegistrationRate(_this.filteredStudents);
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this.isLoading = false;
      });
    },
    filterList: function filterList() {
      var list = this.students;
      var keyword = this.filterParam.keyword;
      var class_id = this.filterParam.classroom;
      var hasGuardian = this.filterParam.hasGuardian;

      if (keyword) {
        keyword = keyword.trim().split(/[\x20\u3000]+/);
        list = list.filter(function (v) {
          return Object.keys(v).some(function (k) {
            return keyword.find(function (kw) {
              return String(v[k]).indexOf(kw) > -1;
            });
          });
        });
      }

      if (class_id) {
        list = list.filter(function (v) {
          return v.class_id === class_id;
        });
      }

      if (hasGuardian) {
        list = list.filter(function (v) {
          return !(v.guardian && v.guardian.length > 0);
        });
      }

      this.filteredStudents = list;
      this.calcRegistrationRate(this.filteredStudents);
    },
    resetFilterParam: function resetFilterParam() {
      this.filterParam.keyword = "";
      this.filterParam.classroom = false;
      this.filterParam.hasGuardian = false;
      this.filteredStudents = this.students;
      this.calcRegistrationRate(this.filteredStudents);
    },
    calcRegistrationRate: function calcRegistrationRate(students) {
      var _this2 = this;

      this.hasGuardian = 0;
      this.numberOfStudents = students.length;
      students.forEach(function (v) {
        if (v.guardian && v.guardian.length > 0) {
          _this2.hasGuardian++;
        }
      });
      this.registrationRate = Math.round(this.hasGuardian / this.numberOfStudents * 100);
    },
    confirmGetQrCode: function confirmGetQrCode() {
      var _this3 = this;

      this.targetStudentIds = this.filteredStudents.map(function (v) {
        return v.id;
      });
      this.isLoading = true;
      getDataByPostMethod("reader/get_students_regenerated_qrcode", {
        student_id: this.targetStudentIds
      }).then(function (data) {
        _this3.isLoading = false;
        _this3.confirmMessageTitle = "QRコード表示（全生徒）";
        _this3.showConfirmModal = true;

        if (data.items.length > 0) {
          var nameSamples = data.items.slice(0, 3).reduce(function (p, v) {
            return p + v.name + "さん" + "、";
          }, "");
          var othersCount = data.items.length > 3 ? "ほか" + (data.items.length - 3) + "名" : "";
          _this3.confirmMessage = "QRコードを一度発行している児童生徒が" + data.items.length + "名含まれています。\n" + nameSamples + othersCount + "\n" + "表示されたページを印刷機能を利用してPDFに保存してください。\n" + "有効期限は" + moment().add(3, "month").format("YYYY年MM月DD日") + "です。\n" + "<span class='error bold'>※前回印刷したQRコードは使用できなくなります。</span>";
        } else {
          _this3.confirmMessage = _this3.targetStudentIds.length + "人分のQRコードを発行します。\n" + "表示されたページを印刷機能を利用してPDFに保存してください。\n" + "有効期限は" + moment().add(3, "month").format("YYYY年MM月DD日") + "です。\n" + "<span class='error bold'>※前回印刷したQRコードは使用できなくなります。</span>";
        }
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this3.isLoading = false;
      });
    },
    confirmGetQrCodeSingle: function confirmGetQrCodeSingle(student) {
      this.targetStudentIds = [student.id];
      this.confirmMessageTitle = "QRコード表示";
      this.confirmMessage = student.name + "さんのQRコードを発行します。\n" + "表示されたページを印刷機能を利用してPDFに保存してください。\n" + "\n" + "有効期限は" + moment().add(3, "month").format("YYYY年MM月DD日") + "です。\n" + "<span class='error bold'>※前回印刷したQRコードは使用できなくなります。</span>";
      this.showConfirmModal = true;
    },
    getQrCode: function getQrCode() {
      this.showConfirmModal = false;
      this.$router.push({
        name: "students.qr_generate",
        params: {
          student_id: this.targetStudentIds
        }
      });
    },
    cancelConfirm: function cancelConfirm() {
      this.showConfirmModal = false;
    }
  }
};