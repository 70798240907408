/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./GovForumIndex.vue?vue&type=template&id=08a1a2e2&scoped=true"
import script from "./GovForumIndex.vue?vue&type=script&setup=true&lang=js"
export * from "./GovForumIndex.vue?vue&type=script&setup=true&lang=js"
import style0 from "./GovForumIndex.vue?vue&type=style&index=0&id=08a1a2e2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a1a2e2",
  null
  
)

export default component.exports