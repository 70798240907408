import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import { computed } from "vue";
import { useStore } from "vue2-helpers/vuex";
export default {
  __name: 'MonthlyTableHead',
  props: {
    mode: {
      type: String,
      default: "attendance"
    },
    monthData: {
      /** @type {PropType<MonthData[]>} */
      type: Array,
      required: true
    },
    showSubHead: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    var props = __props; // @ts-check

    var store = useStore();
    var studentAddress = computed(function () {
      return store.state.studentAddress;
    });
    var schoolDayLength = computed(function () {
      return props.monthData.filter(function (v) {
        return !v.isSchoolDayOff;
      }).length;
    });
    return {
      __sfc: true,
      store: store,
      props: props,
      studentAddress: studentAddress,
      schoolDayLength: schoolDayLength
    };
  }
};