import _objectSpread from "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import { logout } from "@/services/auth";
import { getData, createData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import moment from "moment";
import { mapStores } from "pinia";
import { useGradeClassroomStore } from "@/stores/gradeClassroom";
import { useGroupStore } from "@/stores/group";
import { useSchoolPlanStore } from "@/stores/schoolPlan";
export default {
  name: "AppInit",
  components: {
    LoadingMessage: LoadingMessage
  },
  data: function data() {
    return {
      pageTitle: "初期化中",
      isLoading: {
        school: true,
        grade: true,
        classroom: true,
        group: true
      },
      loadingMessage: "基本データを読み込んでいます…しばらくお待ち下さい"
    };
  },
  head: {
    title: function title() {
      return {
        inner: this.pageTitle
      };
    },
    meta: []
  },
  computed: _objectSpread({}, mapStores(useGroupStore, useGradeClassroomStore, useSchoolPlanStore)),
  created: function created() {
    var _this = this;

    getData(["schools?with[]=school_plan&with[]=government", // 学校情報
    "grades?with=classroom", // 学年
    "reader/get_classroom_with_students_count", // クラス
    "groups", // グループ
    "teachers/whoami", // ログインユーザ
    "holidays", // 祝日
    "attendance_types?with=attendance_book_counting_type", // 欠席種別
    "attendance_book_counting_types" // 出席簿表示種別
    ]).then(function (data) {
      _this.$store.dispatch("commitLoginStatus", true);

      var school = data[0].items[0];

      if (!school) {
        alert("あなたの学校はCOCOOの契約を終了されました。ご利用ありがとうございました");
        logout();
      }

      _this.schoolPlanStore.schoolPlan = school.school_plan.allow;
      delete school.school_plan;

      _this.$store.dispatch("commitSchool", school);

      var currentFiscalYear = moment().add(-3, "month").year();
      var threeYearsAgo = moment().add(-3, "month").add(-3, "year").year();
      var firstFiscalYear = moment(school.created_at).add(-3, "month").year() > threeYearsAgo ? moment(school.created_at).add(-3, "month").year() : threeYearsAgo;
      var fiscalYears = [];
      var i;

      for (i = firstFiscalYear; i <= currentFiscalYear; i++) {
        if (i === currentFiscalYear) {
          fiscalYears.push({
            value: currentFiscalYear,
            label: "今年度"
          });
        } else {
          fiscalYears.push({
            value: i,
            label: i + "年度"
          });
        }
      }

      _this.$store.dispatch("commitFiscalYears", fiscalYears);

      var grades = data[1].items;

      _this.$store.dispatch("commitGrade", grades);

      _this.gradeClassroomStore.grades = grades; // pinia使用のため重複管理を許容する

      var classrooms = data[2].items;

      _this.$store.dispatch("commitClassroom", classrooms);

      _this.gradeClassroomStore.classrooms = classrooms; // pinia使用のため重複管理を許容する

      _this.groupStore.groups = data[3].items;
      var me = data[4].items[0];

      _this.$store.dispatch("commitSelfProfile", me);

      _this.$store.dispatch("commitHolidays", data[5].items);

      _this.$store.dispatch("commitAttendanceTypes", data[6].items);

      _this.$store.dispatch("commitAttendanceBookCountingTypes", data[7].items); // 学校の区分によって生徒の呼称を変える


      switch (data[0].items[0].type) {
        case "el":
          _this.$store.dispatch("commitStudentAddress", "児童");

          break;

        case "jh":
          _this.$store.dispatch("commitStudentAddress", "生徒");

          break;

        case "hs":
          _this.$store.dispatch("commitStudentAddress", "生徒");

          break;

        default:
          _this.$store.dispatch("commitStudentAddress", "生徒");

          break;
      }

      if (me.plan === "standard") {
        _this.$store.dispatch("commitEnableEmergencyCall", true);
      }
    }).catch(function (error) {
      console.log(error);
    }).then(function () {
      if (_this.$store.state.me.last_login) {
        createData("writer/write_last_login").then(function () {
          if (localStorage.getItem("redirectBack")) {
            _this.$router.push(localStorage.getItem("redirectBack"));
          } else {
            _this.$router.push({
              name: "dashboard"
            });
          }
        }).catch(function (error) {
          console.log(error);
        });
      } else {
        _this.$router.push({
          name: "agreements"
        });
      }
    });
    setTimeout(function () {
      return _this.loadingMessage = "読み込みに時間がかかっています。このまま画面が切り替わらない場合はブラウザの再読込をお試しください";
    }, 10000);
  }
};