import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { useStore } from "vue2-helpers/vuex";
import { storeToRefs } from "pinia";
import { useGroupStore } from "@/stores/group";
import RowItem from "@/components/RowItem.vue";
export default {
  __name: 'GroupClassSelectForm',
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    filter: {
      type: Object,
      default: function _default() {}
    }
  },
  emits: ["handleClassroomChange", "handleGroupChange"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props; // @ts-check

    var route = useRoute();
    var router = useRouter();
    var store = useStore();

    var _storeToRefs = storeToRefs(useGroupStore()),
        studentGroups = _storeToRefs.studentGroups;

    var classroomId = ref(props.filter.classroomId);
    var groupId = ref(props.filter.groupId);
    /** @type {ComputedRef<Object<string, any>[]>} sortedGroups */

    var sortedGroups = computed(function () {
      return studentGroups.value.slice().sort(function (
      /** @type {Object<string, any>} */
      n,
      /** @type {Object<string, any>} */
      m) {
        if (n.name < m.name) {
          return -1;
        } else if (n.name > m.name) {
          return 1;
        } else {
          return 0;
        }
      });
    });
    var currentGradeList = computed(function () {
      return store.state.grades.filter(function (v) {
        return !v.fiscal_year;
      });
    });

    var filterClass = function filterClass(gradeClassroom) {
      if (Number(props.filter.groupId) === 0) {
        return gradeClassroom;
      } // グループ指定がある場合は所属する生徒がいるクラスを抽出する


      var group = studentGroups.value.find(function (v) {
        return v.id === props.filter.groupId;
      });

      if (group) {
        var belongGroupClassroom = new Set(group.student.map(function (v) {
          return v.class_id;
        }));
        return gradeClassroom.filter(function (v) {
          return belongGroupClassroom.has(v.id);
        });
      }

      return [];
    };

    var handleClassroomChange = function handleClassroomChange() {
      if (route.query.temperature) {
        router.push({
          name: route.name,
          query: {
            classroom_id: classroomId.value,
            year_month: props.filter.yearMonth,
            temperature: "1"
          }
        });
      } else {
        router.push({
          name: route.name,
          query: {
            classroom_id: classroomId.value,
            year_month: props.filter.yearMonth
          }
        });
      }

      emit("handleClassroomChange", classroomId.value);
    };

    var handleGroupChange = function handleGroupChange() {
      if (route.query.temperature) {
        router.push({
          name: route.name,
          query: {
            group_id: groupId.value,
            year_month: props.filter.yearMonth,
            temperature: "1"
          }
        });
      } else {
        router.push({
          name: route.name,
          query: {
            group_id: groupId.value,
            year_month: props.filter.yearMonth
          }
        });
      }

      classroomId.value = 0;
      emit("handleGroupChange", groupId.value);
    };

    return {
      __sfc: true,
      route: route,
      router: router,
      store: store,
      studentGroups: studentGroups,
      props: props,
      emit: emit,
      classroomId: classroomId,
      groupId: groupId,
      sortedGroups: sortedGroups,
      currentGradeList: currentGradeList,
      filterClass: filterClass,
      handleClassroomChange: handleClassroomChange,
      handleGroupChange: handleGroupChange,
      RowItem: RowItem
    };
  }
};