import _objectSpread from "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import MessageModal from "../common/MessageModal.vue";
import store from "../../services/store.js";
import { mapState } from "vuex";
import { createData, getData, updateData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import emailValidator from "email-validator";
import RowItem from "../RowItem";
export default {
  store: store,
  name: "EditGuardian",
  components: {
    RowItem: RowItem,
    MessageModal: MessageModal,
    LoadingMessage: LoadingMessage
  },
  data: function data() {
    return {
      showModalCompleteCreate: false,
      showModalCompleteSave: false,
      messageSentResult: true,
      showModalSelectClass: false,
      showModal: false,
      student: [],
      guardian: {
        name: "",
        ruby: "",
        relationship: "",
        locale: "ja-JP",
        email: "",
        phone: "",
        normal_receive_method: "email",
        emergency_receive_method: "email",
        memo: "",
        role_id: 22,
        target_student_id: this.$route.query.student_id,
        existence_guardian: false
      },
      id: this.$route.params.id,
      isPrimaryGuardian: this.$route.params.guardians === 0,
      questioneeFlagLock: false,
      returnPageId: false,
      isLoading: {
        getData: true,
        postData: false,
        phoneExists: false
      },
      loadingMessage: "",
      error: {
        name: null,
        ruby: null,
        phone: null,
        email: null
      },
      isNewPhone: false,
      isNewPhoneAndEmail: false,
      showModalPhoneExists: false,
      showModalPhoneAndEmailExists: false,
      showModalDuplicativeGuardianExists: false,
      guardianInfoAttached: false,
      foundGuardian: {
        name: "",
        ruby: "",
        relationship: "",
        email: "",
        phone: ""
      }
    };
  },
  computed: _objectSpread({}, mapState(["classrooms", "studentAddress", "me", "capabilities"])),
  created: function created() {
    if (this.$store.state.me.role_id > 11) {
      this.$router.push({
        name: "dashboard"
      });
    }

    this.setData();
  },
  methods: {
    validatePhone: function validatePhone() {
      return typeof this.guardian.phone === "string" && this.guardian.phone.match(/^\d{10,11}$/);
    },
    checkPhoneExists: function checkPhoneExists() {
      var _this = this;

      this.isNewPhone = false;

      if (!this.guardianInfoAttached && this.validatePhone()) {
        if (emailValidator.validate(this.guardian.email)) {
          this.checkPhoneAndEmailExists();
          return;
        }

        this.isLoading.phoneExists = true; //todo 関係者との電話番号重複チェック

        getData("reader/get_guardian_from_phone?phone=" + this.guardian.phone + (this.id ? "&guardian_id=" + this.id : "")).then(function (data) {
          if (data.items[0] === null) {
            _this.isNewPhone = true;
          } else {
            var guardian = data.items[0];

            if (guardian.guardian_student.length > 0 && guardian.guardian_student.find(function (v) {
              return v.student_id === _this.guardian.target_student_id;
            })) {
              _this.error.phone = "既にこの電話番号はこの" + _this.studentAddress + "に登録されています。別の番号を入力してください。";
              _this.guardian.phone = "";
            } else {
              _this.showModalPhoneExists = true;
              _this.guardian.existence_guardian = guardian.id;
              _this.foundGuardian = {
                name: guardian.name,
                ruby: guardian.ruby,
                relationship: guardian.relationship,
                email: guardian.user.email,
                phone: guardian.phone,
                locale: guardian.locale,
                memo: guardian.memo,
                normal_receive_method: guardian.normal_receive_method,
                emergency_receive_method: guardian.emergency_receive_method
              };
            }
          }
        }).catch(function (error) {
          console.log(error);
        }).then(function () {
          _this.isLoading.phoneExists = false;
        });
      }
    },
    checkPhoneAndEmailExists: function checkPhoneAndEmailExists() {
      var _this2 = this;

      this.isNewPhoneAndEmail = false;

      if (!this.guardianInfoAttached && this.validatePhone() && emailValidator.validate(this.guardian.email)) {
        this.isLoading.phoneAndEmailExists = true;
        getData("reader/get_guardian_over_school?phone=".concat(this.guardian.phone, "&email=").concat(encodeURIComponent(this.guardian.email)).concat(this.id ? "&guardian_id=" + this.id : "")).then(function (data) {
          if (data.items[0] === null) {
            _this2.isNewPhoneAndEmail = true;
          } else {
            var guardian = data.items[0];

            if (guardian.guardian_student.length > 0 && guardian.guardian_student.find(function (v) {
              return v.student_id === _this2.guardian.target_student_id;
            })) {
              _this2.error.phone = "既にこの電話番号とメールアドレスはこの" + _this2.studentAddress + "に登録されています。別の情報を入力してください。";
              _this2.guardian.phone = "";
            } else {
              _this2.showModalPhoneAndEmailExists = true;
              _this2.guardian.existence_guardian = guardian.id;
              _this2.foundGuardian = {
                name: guardian.name,
                ruby: guardian.ruby,
                relationship: guardian.relationship,
                email: guardian.user.email,
                phone: guardian.phone,
                locale: guardian.locale,
                memo: guardian.memo,
                normal_receive_method: guardian.normal_receive_method,
                emergency_receive_method: guardian.emergency_receive_method
              };
            }
          }
        }).catch(function (error) {
          console.log(error);
        }).then(function () {
          _this2.isLoading.isNewPhoneAndEmail = false;
        });
      }
    },
    attachGuardian: function attachGuardian() {
      this.showModalPhoneExists = false;
      this.showModalPhoneAndEmailExists = false;
      this.guardian.name = this.foundGuardian.name;
      this.guardian.ruby = this.foundGuardian.ruby;
      this.guardian.relationship = this.foundGuardian.relationship;
      this.guardian.email = this.foundGuardian.email;
      this.guardian.phone = this.foundGuardian.phone;
      this.guardian.locale = this.foundGuardian.locale;
      this.guardian.memo = this.foundGuardian.memo;
      this.guardian.normal_receive_method = this.foundGuardian.normal_receive_method;
      this.guardian.emergency_receive_method = this.foundGuardian.emergency_receive_method;
      this.guardianInfoAttached = true;
    },
    cancelAttachGuardian: function cancelAttachGuardian() {
      this.guardian.existence_guardian = false;
      this.showModalPhoneExists = false;
      this.showModalPhoneAndEmailExists = false;
      this.guardianInfoAttached = false;
      this.guardian.phone = "";
      this.foundGuardian = {};
    },
    setData: function setData() {
      var _this3 = this;

      if (this.isPrimaryGuardian) {
        this.guardian.role_id = 21;
      } // params :id が来ている場合は編集なので編集データを取り出す


      if (this.id) {
        getData("guardians/" + this.id + "?with=user").then(function (data) {
          _this3.guardian = data.items.map(function (v) {
            var d = v;
            d.email = d.user.email;
            return d;
          });
          _this3.guardian = _this3.guardian[0];
          _this3.guardian.role_id = _this3.guardian.user.role_id;
          _this3.guardian.target_student_id = _this3.$route.query.student_id;
          _this3.isPrimaryGuardian = _this3.questioneeFlagLock = _this3.guardian.user.role_id === 21;
        }).catch(function (error) {
          console.log(error);
        }).then(function () {
          _this3.isLoading.getData = false;
        });
      } else {
        this.isLoading.getData = false;
      }
    },
    postGuardian: function postGuardian() {
      var _this4 = this;

      createData("writer/write_guardian", {
        guardian: this.guardian
      }).then(function (data) {
        _this4.$store.dispatch("commitModalMessage", {
          message: "保護者情報登録が完了しました",
          title: "登録完了"
        });

        _this4.returnPageId = data.message.student_id;
        _this4.showModal = true;
      }).catch(function (error) {
        if (error.response && error.response.status === 409) {
          _this4.showModalDuplicativeGuardianExists = true;
        }
      }).then(function () {
        _this4.isLoading.postData = false;
      });
    },
    putGuardian: function putGuardian() {
      var _this5 = this;

      updateData("writer/write_guardian/" + this.id, {
        guardian: this.guardian
      }).then(function (data) {
        _this5.$store.dispatch("commitModalMessage", {
          message: _this5.studentAddress + "・保護者情報更新が完了しました",
          title: "更新完了"
        });

        _this5.returnPageId = data.message.student_id;
        _this5.showModal = true;
      }).catch(function (error) {
        if (error.response && error.response.status === 409) {
          _this5.showModalDuplicativeGuardianExists = true;
        }
      }).then(function () {
        _this5.isLoading.postData = false;
      });
    },
    saveData: function saveData() {
      var valid = true;
      this.error = {
        name: null,
        ruby: null,
        phone: null
      };

      if (!this.guardian.name) {
        this.error.name = "名前を入力してください。";
        valid = false;
      } else {
        this.guardian.name = this.guardian.name.replace(/\s+/, " ");
      }

      if (!this.guardian.ruby) {
        this.error.ruby = "ふりがなを入力してください。";
        valid = false;
      } else {
        this.guardian.ruby = this.guardian.ruby.replace(/\s+/, " ");
      }

      if (!this.guardian.phone) {
        this.error.phone = "電話番号を入力してください。";
        valid = false;
      } else {
        var regex = new RegExp(/^[0-9]+$/);

        if (!regex.test(this.guardian.phone)) {
          this.error.phone = "数字のみで入力してください。";
          valid = false;
        }
      } //緊急のお知らせ無しのプランで、メールアドレスが存在せず電話番号のみのときはバリデーションで止まらないように便宜上emergency_receive_methodをphoneにしてあげる


      if (!this.capabilities.enableEmergencyCall && !this.guardian.email && this.guardian.emergency_receive_method === "email") {
        this.guardian.emergency_receive_method = "phone";
      }

      if (this.guardian.normal_receive_method === "email" || this.guardian.emergency_receive_method === "email" || this.guardian.emergency_receive_method === "both") {
        if (!this.guardian.email) {
          this.error.email = "お知らせ方法にメールを指定している場合はメールアドレスを入力してください。";
          valid = false;
        } else if (!emailValidator.validate(this.guardian.email)) {
          this.error.email = "メールアドレスの形式が正しくありません。";
          valid = false;
        }
      }

      if (!valid) {
        return;
      }

      this.isLoading.postData = true;

      if (this.id) {
        this.loadingMessage = "保護者情報を更新しています。";
        this.putGuardian();
      } else {
        this.loadingMessage = "保護者情報を登録しています。";
        this.postGuardian();
      }
    },
    modalClosed: function modalClosed() {
      this.showModal = false;
      this.$router.push({
        name: "students.detail",
        params: {
          id: this.returnPageId
        }
      });
    }
  }
};