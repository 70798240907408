import _objectSpread from "/Users/k-tanaka/project/137/cocoo_school_admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.dom-collections.for-each.js";
import RowItem from "../RowItem";
import moment from "moment";
import { getData } from "@/services/axios";
import LoadingMessage from "@/components/common/LoadingMessage";
import { mapState } from "vuex";
export default {
  name: "Events",
  components: {
    RowItem: RowItem,
    LoadingMessage: LoadingMessage
  },
  filters: {
    trim: function trim(value) {
      if (!value) return "";
      if (value.length <= 10) return value;
      return value.slice(0, 10) + "..";
    }
  },
  data: function data() {
    return {
      isLoading: false,
      events: [],
      filteredEvents: [],
      futureEvents: [],
      dailyClassroomEvents: {},
      monthData: [],
      yearMonth: null,
      keyword: "",
      date: null,
      myEventOnly: false,
      showPast: false,
      visibleClassroomId: false,
      filteredClassrooms: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(["grades", "classrooms", "holidays", "studentAddress", "me"])), {}, {
    fiscalYear: function fiscalYear() {
      return moment(this.yearMonth).add(-3, "month").year();
    },
    localYearMonth: function localYearMonth() {
      return moment(this.yearMonth).format("YYYY年MM月");
    }
  }),
  created: function created() {
    var _this = this;

    this.yearMonth = this.$route.query.year_month ? this.$route.query.year_month : moment().format("YYYY-MM");

    if (this.$route.query.date) {
      this.date = this.$route.query.date;
    }

    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }

    this.filteredClassrooms = this.classrooms;
    var date = moment(this.yearMonth).startOf("month");
    this.monthlyDates(date);
    this.setData().then(function () {
      _this.search();
    });
  },
  methods: {
    setData: function setData() {
      var _this2 = this;

      this.isLoading = true;
      return getData("events?with[]=classroom&with[]=teacher&orderby=updated_at&order=desc").then(function (data) {
        _this2.filteredEvents = _this2.events = data.items.map(function (v) {
          var target = "";
          v.classroom.forEach(function (v2, i) {
            var c = _this2.classrooms.find(function (v3) {
              return v3.id === v2.classroom_id;
            });

            if (c) {
              if (i < 3) {
                target += (i === 0 ? "" : ", ") + c.name;
              } else if (i === 3) {
                target += "ほか";
              }
            }
          });
          v.target = target;

          if (v.is_all_day) {
            v.formattedDate = moment(v.from).format("MM月DD日(ddd)");

            if (moment(v.from).format("YYYY-MM-DD") !== moment(v.to).format("YYYY-MM-DD")) {
              v.formattedDate += " - " + moment(v.to).format("MM月DD日(ddd)");
            }
          } else {
            v.formattedDate = moment(v.from).format("MM月DD日(ddd) HH:mm");

            if (moment(v.from).format("YYYY-MM-DD") === moment(v.to).format("YYYY-MM-DD")) {
              v.formattedDate += " - " + moment(v.to).format("HH:mm");
            } else {
              v.formattedDate += " - " + moment(v.to).format("MM月DD日(ddd) HH:mm");
            }
          }

          v.fromDate = moment(v.from).format("YYYY-MM-DD");
          v.toDate = moment(v.to).format("YYYY-MM-DD");
          v.formattedUpdatedAt = moment(v.updated_at).format("MM月DD日(ddd) HH:mm");
          return v;
        });

        _this2.assignDailyClassroomEvents();

        _this2.futureEvents = _this2.filteredEvents.filter(function (v) {
          return moment(v.to) >= moment().startOf("day");
        });
      }).catch(function (error) {
        console.log(error);
      }).then(function () {
        _this2.isLoading = false;
      });
    },
    assignDailyClassroomEvents: function assignDailyClassroomEvents() {
      var _this3 = this;

      this.dailyClassroomEvents = {};
      this.filteredEvents.forEach(function (v) {
        v.classroom.forEach(function (c) {
          if (!_this3.dailyClassroomEvents[c.classroom_id]) {
            _this3.dailyClassroomEvents[c.classroom_id] = {};
            _this3.dailyClassroomEvents[c.classroom_id][v.fromDate] = [v];

            if (v.fromDate !== v.toDate) {
              var from = moment(v.fromDate);
              var to = moment(v.toDate);

              while (from < to) {
                from.add(1, "day");
                _this3.dailyClassroomEvents[c.classroom_id][from.format("YYYY-MM-DD")] = [v];
              }
            }
          } else {
            if (!_this3.dailyClassroomEvents[c.classroom_id][v.fromDate]) {
              _this3.dailyClassroomEvents[c.classroom_id][v.fromDate] = [v];
            } else {
              _this3.dailyClassroomEvents[c.classroom_id][v.fromDate].push(v);
            }

            if (v.fromDate !== v.toDate) {
              var _from = moment(v.fromDate);

              var _to = moment(v.toDate);

              while (_from < _to) {
                _from.add(1, "day");

                var d = _from.format("YYYY-MM-DD");

                if (!_this3.dailyClassroomEvents[c.classroom_id][d]) {
                  _this3.dailyClassroomEvents[c.classroom_id][d] = [v];
                } else {
                  _this3.dailyClassroomEvents[c.classroom_id][d].push(v);
                }
              }
            }
          }
        });
      });
    },
    monthlyDates: function monthlyDates(date) {
      var days = date.daysInMonth();
      this.monthData = [];

      for (var i = 0; i < days; i++) {
        this.monthData.push({
          ymd: date.format("YYYY-MM-DD"),
          d: Number(date.format("d")),
          D: Number(date.format("D")),
          isHoliday: this.holidays.findIndex(function (v) {
            return v === date.format("YYYY-MM-DD");
          }) > -1,
          weekName: this.getWeekNameJP(date.format("d"))
        });
        date.add(1, "d");
      }
    },
    monthChange: function monthChange(mode) {
      if (!this.isLoading) {
        var date = moment(this.yearMonth);

        if (mode === "add") {
          date.add(1, "months");
        } else if (mode === "sub") {
          date.subtract(1, "months");
        }

        this.yearMonth = date.format("YYYY-MM");
        this.monthlyDates(date);
        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            yearMonth: this.yearMonth
          })
        });
      }
    },
    getWeekNameJP: function getWeekNameJP(id) {
      return ["日", "月", "火", "水", "木", "金", "土"][id];
    },
    search: function search(push) {
      var _this4 = this;

      var events = this.events;

      if (this.date) {
        events = events.filter(function (v) {
          return moment(v.fromDate) <= moment(_this4.date) && moment(_this4.date) <= moment(v.toDate);
        });
      }

      if (this.keyword) {
        events = events.filter(function (v) {
          return v.title.indexOf(_this4.keyword) > -1;
        });
      }

      if (this.myEventOnly) {
        events = events.filter(function (v) {
          return v.teacher && v.teacher.user_id === _this4.me.user_id;
        });
      }

      if (this.visibleClassroomId) {
        events = events.filter(function (v) {
          return v.classroom.find(function (c) {
            return c.classroom_id === _this4.visibleClassroomId;
          });
        });
      }

      this.filteredEvents = events;
      this.assignDailyClassroomEvents();

      if (this.showPast) {
        this.futureEvents = this.filteredEvents.filter(function (v) {
          return moment(v.to) < moment().startOf("day");
        });
      } else {
        this.futureEvents = this.filteredEvents.filter(function (v) {
          return moment(v.to) >= moment().startOf("day");
        });
      }

      if (this.visibleClassroomId) {
        this.filteredClassrooms = this.classrooms.filter(function (v) {
          return v.id === _this4.visibleClassroomId;
        });
      } else {
        this.filteredClassrooms = this.classrooms;
      }

      if (push) {
        this.$router.push({
          query: {
            date: this.date,
            keyword: this.keyword,
            visibleClassroomId: this.visibleClassroomId
          }
        });
      }
    }
  }
};